export const shouldShowManageAccountButton = (account, isTeamOwner) => {
    if (!account || !isTeamOwner) {
        return false;
    }
    return account.status !== 'rejected' && account.status !== 'under_review';
};

export const shouldShowTeamBanner = (account) => {
    if (!account) {
        return false;
    }
    return account.status !== 'approved';
};
