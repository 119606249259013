import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReportsHeader from 'components/baseComponents/ReportsHeader';
import ReportsTable from 'components/baseComponents/ReportsTable';
import { formatDate, getStartOfDay, getEndOfDay } from 'utils/date';
import { repeatCharacter, camelCaseToWords } from 'utils/helpers';
import { linkToDepositsShow } from 'utils/links';
import { matchPropShape, paginatePropShape } from 'interfaces/navigation';
import { payoutPropShape } from 'interfaces/payout';

class Deposits extends Component {
  componentDidMount () {
    const { payouts, payoutsLoading } = this.props;
    if (payouts.length === 0 && !payoutsLoading) {
      this.fetch();
    }
  }

  fetch = (params = {}, direction = null) => {
    let fetchPayouts = this.props.fetchPayouts;
    if (direction === 'next') {
      fetchPayouts = this.props.fetchNextPayouts;
    } else if (direction === 'prev') {
      fetchPayouts = this.props.fetchPrevPayouts;
    }

    const arrivalDateGte = params.startDate ? getStartOfDay(params.startDate) : null;
    const arrivalDateLte = params.endDate ? getEndOfDay(params.endDate) : null;

    fetchPayouts({ arrivalDateGte, arrivalDateLte });
  }

  render () {
    const { payouts, paginate, match: { params }, payoutsLoading } = this.props;

    const columns = [
      { name: 'arrivalDate', label: 'Arrival On', isSortable: false },
      { name: 'amountWithCurrency', label: 'Amount', isSortable: false },
      { name: 'status', label: 'Status', isSortable: false },
      {
        name: 'paymentAccount',
        label: 'Account',
        isSortable: false,
        render: (column, row) => row[column.name] && (
          <>
            { row[column.name].name }
            <span style={ { paddingLeft: '8px' } }>{ repeatCharacter(4, '\u2022') } { row[column.name].last4 }</span>
          </>
        ),
        renderCSV: (item) => (
          !item.name ? '' : `${item.name}: ${item.last4}`
        )
      },
      {
        name: 'actions',
        label: null,
        render: (column, row) => row[column.name] &&
          <Link to={ linkToDepositsShow({ ...params, depositId: row[column.name] }) }>
            View Details
          </Link>,
        renderCSV: null
      }
    ];

    const formatRows = payouts => payouts.map((payout) => ({
      ...payout,
      status: camelCaseToWords(payout.status),
      arrivalDate: formatDate(payout.arrivalDate),
      ...(payout.destination.bankName &&
        { paymentAccount: { name: payout.destination.bankName, last4: payout.destination.last4 } }),
      ...(payout.destination.brand &&
        { paymentAccount: { name: payout.destination.brand, last4: payout.destination.last4 } }),
      actions: payout.id
    }));
    const rows = formatRows(payouts);

    return (
      <div>
        <ReportsHeader params={ params } />
        <ReportsTable
          loading={ payoutsLoading }
          columns={ columns }
          rows={ rows }
          fetch={ this.fetch }
          paginate={ paginate }
          dataType='payouts'
          formatRows={ formatRows }
        />
      </div>
    );
  }
}

Deposits.propTypes = {
  fetchPayouts: PropTypes.func.isRequired,
  fetchNextPayouts: PropTypes.func.isRequired,
  fetchPrevPayouts: PropTypes.func.isRequired,
  payouts: PropTypes.arrayOf(PropTypes.shape(payoutPropShape)),
  paginate: PropTypes.shape(paginatePropShape),
  match: PropTypes.shape(matchPropShape).isRequired,
  payoutsLoading: PropTypes.bool
};

Deposits.defaultProps = {
  payouts: [],
  paginate: {},
  payoutsLoading: false
};

export default Deposits;
