import React from 'react';
import PropTypes from 'prop-types';
import states from 'utils/us-states';
import Select from './Select';
import FieldError from 'components/teamsnapUIExtensions/FieldError';
import { metaPropShape } from 'interfaces/field';

const StateSelect = ({ input, meta }) => {
  const options = states.map(state => ({ label: state.name, value: state.abbreviation }));

  return (
    <>
      <Select field={ input } options={ [{ label: 'Select State', value: '' }, ...options] } />
      { meta.error && meta.touched && <FieldError>{ meta.error }</FieldError> }
    </>
  );
};

StateSelect.propTypes = {
  input: PropTypes.shape({}).isRequired,
  meta: PropTypes.shape(metaPropShape).isRequired
};

export default StateSelect;
