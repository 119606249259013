import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReportsHeader from 'components/baseComponents/ReportsHeader';
import ReportsTable from 'components/baseComponents/ReportsTable';
import ReportsAnchorTag from 'components/baseComponents/ReportsAnchorTag';
import { formatDate, getStartOfDay, getEndOfDay, getThirtyDaysAgo } from 'utils/date';
import { repeatCharacter, truncate, camelCaseToWords } from 'utils/helpers';
import { matchPropShape, paginatePropShape } from 'interfaces/navigation';
import { chargePropShape } from 'interfaces/charge';

class Payments extends Component {
  componentDidMount () {
    const { charges, chargesLoading, paymentAccountIsLoading } = this.props;
    if (charges.length === 0 && !chargesLoading && paymentAccountIsLoading === false) {
      this.fetch();
    }
  }

  fetch = (params = {}, direction = null) => {
    let fetchCharges = this.props.fetchCharges;
    if (direction === 'next') {
      fetchCharges = this.props.fetchNextCharges;
    } else if (direction === 'prev') {
      fetchCharges = this.props.fetchPrevCharges;
    }

    const createdGte = params.startDate ? getStartOfDay(params.startDate) : getStartOfDay(getThirtyDaysAgo());
    const createdLte = params.endDate ? getEndOfDay(params.endDate) : getEndOfDay(new Date());

    fetchCharges({ createdGte, createdLte });
  }

  render () {
    const { charges, paginate, match: { params }, chargesLoading } = this.props;

    const columns = [
      { name: 'createdAt', label: 'Date', isSortable: false },
      {
        name: 'details',
        label: 'Title',
        mods: 'u-size4of24',
        isSortable: false,
        render: (column, row) => (
          <>
            { row[column.name].title }
            <span style={ { display: 'block', fontSize: '12px', color: '#acacac' } }>
              { truncate(row[column.name].description, 132) }
            </span>
          </>
        ),
        renderCSV: (item) => (
          !item.title ? '' : `${item.title}`
        )
      },
      { name: 'amountWithCurrency', label: 'Gross', isSortable: false },
      { name: 'amountRefundedWithCurrency', label: 'Refunded', isSortable: false },
      { name: 'feeWithCurrency', label: 'Fee', isSortable: false },
      { name: 'netWithCurrency', label: 'Net', isSortable: false },
      { name: 'status', label: 'Status', isSortable: false },
      {
        name: 'paymentDetails',
        label: 'Payment Details',
        mods: 'u-size2of24',
        isSortable: false,
        render: (column, row) => row[column.name] && (
          <>
            { row[column.name].name }
            <span style={ { paddingLeft: '8px' } }>{ repeatCharacter(4, '\u2022') } { row[column.name].last4 }</span>
            <span style={ { display: 'block', fontSize: '12px', color: '#acacac' } }>
              { row[column.name].payerName }
            </span>
          </>
        ),
        renderCSV: (item) => (
          `${item.name}: ${item.last4}`
        )
      },
      {
        name: 'teamsnapWebContextUrl',
        label: null,
        mods: 'u-sizeFill',
        render: (column, row) => row[column.name] && <ReportsAnchorTag href={ row[column.name] } />,
        renderCSV: null
      }
    ];

    const formatRows = charges => charges.map((charge) => ({
      ...charge,
      status: camelCaseToWords(charge.status),
      details: { title: charge.reportingTitle, description: charge.reportingDescription },
      participant: charge.reportingDescription,
      payerName: charge.paymentMethodDetails && charge.paymentMethodDetails.payerName,
      amountRefundedWithCurrency: charge.amountRefunded ? charge.amountRefundedWithCurrency : null,
      createdAt: formatDate(charge.createdAt),
      ...(charge.paymentMethodDetails.bankName &&
        {
          paymentDetails: {
            name: charge.paymentMethodDetails.bankName,
            last4: charge.paymentMethodDetails.last4,
            payerName: charge.paymentMethodDetails.payerName
          }
        }),
      ...(charge.paymentMethodDetails.brand &&
        {
          paymentDetails: {
            name: charge.paymentMethodDetails.brand,
            last4: charge.paymentMethodDetails.last4,
            payerName: charge.paymentMethodDetails.payerName
          }
        }),
      paymentLast4: charge.paymentMethodDetails ? charge.paymentMethodDetails.last4 : null
    }));
    const rows = formatRows(charges);

    return (
      <div>
        <ReportsHeader params={ params } />
        <ReportsTable
          loading={ chargesLoading }
          columns={ columns }
          rows={ rows }
          fetch={ this.fetch }
          paginate={ paginate }
          formatRows={ formatRows }
          dataType='charges'
        />
      </div>
    );
  }
}

Payments.propTypes = {
  fetchCharges: PropTypes.func.isRequired,
  fetchNextCharges: PropTypes.func.isRequired,
  fetchPrevCharges: PropTypes.func.isRequired,
  charges: PropTypes.arrayOf(PropTypes.shape(chargePropShape)),
  paginate: PropTypes.shape(paginatePropShape),
  match: PropTypes.shape(matchPropShape).isRequired,
  chargesLoading: PropTypes.bool
};

Payments.defaultProps = {
  charges: [],
  paginate: {},
  chargesLoading: false
};

export default Payments;
