import { connect } from 'react-redux';
import { selectPaymentAccount } from 'state/paymentAccounts/selectors';
import { updatePaymentAccountWithFormFields } from 'state/paymentAccounts/actions';
import { showFlashMessage, hideFlashMessage } from 'state/app/actions';
import AccountsUpdate from './AccountsUpdate';

const mapDispatchToProps = {
  updatePaymentAccountWithFormFields,
  showFlashMessage,
  hideFlashMessage
};

const mapStateToProps = (state, ownProps) => ({
  requestError: state.paymentAccounts.error,
  paymentAccount: selectPaymentAccount(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountsUpdate);
