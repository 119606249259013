const initialState = {
  isLoading: null,
  isError: false,
  items: null,
  error: null
};

const initialStateWithPagination = {
  items: {},
  isError: false,
  error: null,
  paginate: {
    endingBefore: null,
    startingAfter: null,
    hasNext: false,
    hasPrev: false
  },
  isLoading: false
};

// createReducer is a 'reducerFactory', it's a simple and easy to understand reducer that will eliminate
// most of the current boilerplate.  We can also easily "extend" it when necessary.
export const createReducer = (actionName = '') => (state = initialState, action) => {
  switch (action.type) {
    case `FETCH_${actionName}`:
      return {
        ...state,
        isLoading: true,
        isError: false
      };

    case `SAVE_${actionName}`:
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload
      };

    case `UPDATE_${actionName}`:
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload
      };

    case `ERROR_${actionName}`:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload.error
      };

    default:
      return state;
  }
};

// Charges, payouts, and payoutTransactions support pagination and therfore need a separate reducer pattern
export const createReducerWithPagination = (actionName = '') => (state = initialStateWithPagination, action) => {
  switch (action.type) {
    case `FETCH_${actionName}`:
      return {
        ...state,
        isLoading: true,
        isError: false
      };

    case `SAVE_${actionName}`:
      return {
        ...state,
        items: action.payload.data,
        error: null,
        paginate: {
          ...state.paginate,
          ...action.payload.paginate,
          hasNext: action.payload.meta.hasMore,
          hasPrev: false
        },
        isLoading: false
      };

    case `SAVE_NEXT_${actionName}`:
      return {
        ...state,
        items: action.payload.data,
        error: null,
        paginate: {
          ...state.paginate,
          ...action.payload.paginate,
          hasNext: action.payload.meta.hasMore,
          hasPrev: true
        },
        isLoading: false
      };

    case `SAVE_PREV_${actionName}`:
      return {
        ...state,
        items: action.payload.data,
        error: null,
        paginate: {
          ...state.paginate,
          ...action.payload.paginate,
          hasNext: true,
          hasPrev: action.payload.meta.hasMore
        },
        isLoading: false
      };

    case `CLEAR_${actionName}`:
      return {
        ...state,
        items: {},
        isLoading: false,
        isError: false
      };

    case `ERROR_${actionName}`:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload.error
      };

    default:
      return state;
  }
};
