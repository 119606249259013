import { postCreateOnboardingLink } from 'services/paymentsV2';

// ----------------------------------------------------------------------------------------------------
// :: Action Types
// ----------------------------------------------------------------------------------------------------
export const ONBOARDING_LINK = 'ONBOARDING_LINK';
const FETCH_ONBOARDING_LINK = 'FETCH_ONBOARDING_LINK';
const SAVE_ONBOARDING_LINK = 'SAVE_ONBOARDING_LINK';
const ERROR_ONBOARDING_LINK = 'ERROR_ONBOARDING_LINK';

// ----------------------------------------------------------------------------------------------------
// :: Action Creators
// ----------------------------------------------------------------------------------------------------
export const createOnboardingLink = (accountId, data) => async (dispatch) => {
  dispatch({ type: FETCH_ONBOARDING_LINK });
  try {
    const response = await postCreateOnboardingLink(accountId, data);
    return dispatch({ type: SAVE_ONBOARDING_LINK, payload: response.data });
  } catch (error) {
    dispatch({ type: ERROR_ONBOARDING_LINK, payload: error });
    return error;
  }
};
