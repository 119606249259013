import { getCharges } from 'services/payments';
import { selectPaymentAccountId } from 'state/paymentAccounts/selectors';
import { arrayToObject } from 'utils/helpers';

// ----------------------------------------------------------------------------------------------------
// :: Action Types
// ----------------------------------------------------------------------------------------------------
export const CHARGES = 'CHARGES';
const FETCH_CHARGES = 'FETCH_CHARGES';
const SAVE_CHARGES = 'SAVE_CHARGES';
const SAVE_NEXT_CHARGES = 'SAVE_NEXT_CHARGES';
const SAVE_PREV_CHARGES = 'SAVE_PREV_CHARGES';
const ERROR_CHARGES = 'ERROR_CHARGES';

// ----------------------------------------------------------------------------------------------------
// :: Action Creators
// ----------------------------------------------------------------------------------------------------
export const getPaymentCharges = (params) => getCharges({ limit: 50, ...params });

const saveFetchCharges = (actionType, response) => {
  const filtered = response.data.length > 0 ?
    response.data.filter((_item, index) => index === 0 || index === response.data.length - 1).map(x => x.id) : [];

  const paginate = { endingBefore: filtered[0], startingAfter: filtered[1] };

  const mappedData = arrayToObject(response.data, 'id');

  return {
    type: actionType,
    payload: { paginate, data: mappedData, meta: response.meta }
  };
};

export const fetchCharges = (params = {}) => async (dispatch, getState) => {
  dispatch({ type: FETCH_CHARGES });
  const accountId = selectPaymentAccountId(getState());
  try {
    const response = await getPaymentCharges({ accountId, ...params });
    return dispatch(saveFetchCharges(SAVE_CHARGES, response));
  } catch (error) {
    dispatch({ type: ERROR_CHARGES, payload: error });
    return Promise.reject(error);
  }
};

export const fetchNextCharges = (params = {}) => async (dispatch, getState) => {
  dispatch({ type: FETCH_CHARGES });
  const accountId = selectPaymentAccountId(getState());
  const { startingAfter, hasNext } = getState().charges.paginate;
  const pageParams = hasNext ? { startingAfter } : {};

  try {
    const response = await getPaymentCharges({ accountId, ...pageParams, ...params });
    return dispatch(saveFetchCharges(SAVE_NEXT_CHARGES, response));
  } catch (error) {
    dispatch({ type: ERROR_CHARGES, payload: error });
    return Promise.reject(error);
  }
};

export const fetchPrevCharges = (params = {}) => async (dispatch, getState) => {
  const accountId = selectPaymentAccountId(getState());
  const { endingBefore, hasPrev } = getState().charges.paginate;
  const pageParams = hasPrev ? { endingBefore } : {};

  try {
    const response = await getPaymentCharges({ accountId, ...pageParams, ...params });
    return dispatch(saveFetchCharges(SAVE_PREV_CHARGES, response));
  } catch (error) {
    dispatch({ type: ERROR_CHARGES, payload: error });
    return Promise.reject(error);
  }
};
