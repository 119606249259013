import { Cell, Grid, Panel } from '@teamsnap/teamsnap-ui';
import { StyledPanelHeader, StyledSubmitButton } from 'components/baseComponents/AccountsFormBuilder/styled';
import FieldInput from 'components/teamsnapUIExtensions/FieldInput';
import { paymentAccountPropShape } from 'interfaces/paymentAccount';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Form, reduxForm } from 'redux-form';
import { validate } from '../AccountsFormBuilder/validationBuilder';

class IdNumberForm extends Component {
  validator = (value, allValues, _props, name) => {
    const { paymentAccount } = this.props;
    return validate(value, paymentAccount, name, true, allValues);
  };

  render() {
    const { submitting, handleSubmit } = this.props;

    return (
      <Form onSubmit={ handleSubmit }>
        <Panel style={ { marginTop: '20px', width: '48%', marginLeft: 'auto', marginRight: 'auto' } }>
          <Grid isWithGutter isAlignMiddle>
            <Cell mods="u-size1of1" style={ { padding: '0 30px' } }>
              <Grid isWithGutter>
                <Cell mods="u-size1of1">
                  <StyledPanelHeader title="Account ID Number" subtitle="(we must validate your identity)" />
                  <h4>
                    In accordance with federal regulations, we now require a full social security 
                    number of the account representative on file. This satisfies the rules to be 
                    met set forth by Visa/MasterCard/Discover/AMEX for 'Know Your Customer'.
                  </h4>
                  <br />
                </Cell>
                <Cell mods="u-size3of4">
                  <FieldInput
                    name="idNumber"
                    validate={ this.validator }
                    initialValues={ { idNumber: '' } }
                    label="Social Security Number"
                    placeholder="Enter Social Security Number"
                  />
                </Cell>
                <Cell mods="u-size2of4">
                  <StyledSubmitButton isDisabled={ submitting } icon="check" color="primary">
                    Update Account
                  </StyledSubmitButton>
                </Cell>
              </Grid>
            </Cell>
          </Grid>
        </Panel>
      </Form>
    );
  }
}

IdNumberForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  paymentAccount: PropTypes.shape(paymentAccountPropShape),
  submitting: PropTypes.bool.isRequired,
};

IdNumberForm.defaultProps = {
  paymentAccount: null,
};

export default reduxForm({ form: 'idNumber' })(IdNumberForm);
