import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StaticAccountsForm from './StaticAccountsForm';
import { paymentAccountPropShape } from 'interfaces/paymentAccount';

class AccountsUpdateForm extends Component {
  render () {
    const { submitPaymentAccount, paymentAccount } = this.props;

    const initialValues = {
      firstName: '',
      lastName: '',
      addressLine1: '',
      addressLine2: '',
      addressCity: '',
      addressState: '',
      addressPostalCode: '',
      dobDay: '',
      dobMonth: '',
      dobYear: '',
      ssnLast4: '',
      companyName: (paymentAccount && paymentAccount.account && paymentAccount.account.company && paymentAccount.account.company.name) || '',
      companyPhone: ''
    };

    return (
      <StaticAccountsForm
        initialValues={ initialValues }
        isEditing
        paymentAccount={ paymentAccount }
        onSubmit={ (values) => submitPaymentAccount(values) }
      />
    );
  }
}

AccountsUpdateForm.propTypes = {
  paymentAccount: PropTypes.shape(paymentAccountPropShape).isRequired,
  submitPaymentAccount: PropTypes.func.isRequired
};

export default AccountsUpdateForm;
