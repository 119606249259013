import { DateService } from 'frontend-toolkit';

const filterDates = (data = [], dateObj) => {

  if (dateObj) {
    // No dates were selected, return an empty array
    if (!dateObj.startDate && !dateObj.endDate) return data;
    
    // Handle case where we have a start date but no end date
    if (dateObj.startDate && !dateObj.endDate) {
      return data.filter(item => {
        const dateDue = new Date(item.dueDate);

        return dateObj.startDate < dateDue || DateService.datesAreSameDay(dateObj.startDate, dateDue);
      });
    }

    // Handle case where we have an end date but no startdate
    if (!dateObj.startDate && dateObj.endDate) {
      return data.filter(item => new Date(item.dueDate) < dateObj.endDate);
    }

    // Handle a date range
    return data.filter(item => {
      const { dueDate } = item;
      const dateDue = new Date(dueDate);

      return (
        (dateDue > dateObj.startDate && dateObj.endDate > dateDue) ||
        DateService.datesAreSameDay(dateObj.startDate, dateDue)
      );
    });
  }

  // by default, if this is the first time we load the page, generate the
  // report to include all dates in the past up to 10 days in the future
  return data.filter(item => {
    const { dueDate } = item;
    const dateDue = new Date(dueDate);

    return dateDue < DateService.addDaysToDate(10);
  });
};

export default filterDates;
