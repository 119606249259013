import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import { configureStore } from 'state/store';
import * as Sentry from '@sentry/browser';
import * as serviceWorker from './serviceWorker';
import 'assets/index.css';
import App from 'components/baseComponents/App';
import '@teamsnap/snap-ui/build/snap-ui.min.css';

const initialState = {};
const store = configureStore(initialState);

if (process.env.REACT_APP_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://d62830a60862462a9a8da107efe34698@o126035.ingest.sentry.io/1378008',
    environment: process.env.REACT_APP_ENV
  });
}

ReactDOM.render(
  <Provider store={ store }>
    <BrowserRouter>
      <Route component={ App } />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
