export function getVisaBannerText(status) {
    if (status === 'information_required' || status === 'restricted_soon' || status === 'rejected') {
        let textCopy = 'Please provide required details to enable payment processing.';
        if (status === 'rejected') textCopy += ' If you believe this account was rejected in error, please reach out to Payments@TeamSnap.com for support.';
        return textCopy;
    }
    if (status === 'under_review') return 'Wait for account verification.';
}

export const VISA_BANNER_TITLE = 'Deposit account pending verification';
export const VISA_BANNER_ACTION_LABEL = 'Manage account';
