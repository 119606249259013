import { postAcceptTerms } from 'services/payments';

// ----------------------------------------------------------------------------------------------------
// :: Action Types
// ----------------------------------------------------------------------------------------------------
export const ACCEPT_TERMS = 'ACCEPT_TERMS';
const FETCH_ACCEPT_TERMS = 'FETCH_ACCEPT_TERMS';
const ERROR_ACCEPT_TERMS = 'ERROR_ACCEPT_TERMS';
const UPDATE_ACCEPT_TERMS = 'SAVE_ACCEPT_TERMS';

// ----------------------------------------------------------------------------------------------------
// :: Action Creators
// ----------------------------------------------------------------------------------------------------
export const acceptTerms = (paymentAccountId) => async dispatch => {
  try {
    const payload = { paymentAccountId };
    dispatch({ type: FETCH_ACCEPT_TERMS, payload });
    await postAcceptTerms(paymentAccountId);
    dispatch({ type: UPDATE_ACCEPT_TERMS, payload });
  } catch (error) {
    dispatch({ type: ERROR_ACCEPT_TERMS, payload: error });
    return error;
  }
};
