import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import PanelHeader from 'components/teamsnapUIExtensions/PanelHeader';
import { Button } from '@teamsnap/teamsnap-ui';

// Style default PanelHeader from TeamSnapUI
export const StyledPanelHeader = styled(PanelHeader)`
  padding-left: 0;
  padding-bottom: 8px;
  margin-bottom: 15px;
`;

// Style Default Button from TeamSnapUI
export const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const StyledSubmitButton = ({ isDisabled, children, color, icon }) => (
  <StyledButton type='submit' icon={ icon } color={ color } size='large' isDisabled={ isDisabled }>
    { children }
  </StyledButton>
);

StyledSubmitButton.propTypes = {
  isDisabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  icon: PropTypes.string
};

StyledSubmitButton.defaultProps = {
  isDisabled: false,
  color: null,
  icon: 'check'
};
