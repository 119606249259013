import React from 'react';
import PropTypes from 'prop-types';
import { Icon, LabelButton, ModalContainer } from '@teamsnap/snap-ui';

const AccountSetupModal = ({ isOpen, onClose, onAction }) => (
    <ModalContainer
        isOpen={ isOpen }
        closeButton={ false }
        size="default"
    >
        <div className='sui-px-2 sui-pt-2' data-testid='account-setup-modal' >
            <div className="sui-flex sui-items-center" data-testid='setup-modal-header'>
                <Icon name="report" size="xl" filled style={ { color: 'var(--sui-colors-negative-icon, #BF2313)' } } />
                <span className="sui-ml-2 sui-heading-sm sui-font-bold">
                Deposit account pending verification
                </span>
            </div>
            <div className="sui-text-desktop-5 sui-py-4">
                The selected deposit account requires verification. 
                New invoices can be sent once account has been verified.
            </div>
            <div className="sui-pt-2 sui-pb-1 sui-text-right">
                <LabelButton
                className="sui-mr-2"
                labelText="Close"
                onClick={ onClose }
                variantType="secondary"
                />
                { onAction &&
                <LabelButton
                    data-testid="manage-account-button"
                    labelText="Manage account"
                    onClick={ onAction }
                    variantType="primary"
                />
                }
            </div>
        </div>
    </ModalContainer>
);


AccountSetupModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
};

AccountSetupModal.defaultProps = {
};

export default AccountSetupModal;
