import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NewAccountsForm from './NewAccountsForm';

class AccountsNewForm extends Component {
  render () {
    const { submitPaymentAccount, division, creatingOnboardingLink } = this.props;

    const initialValues = {
      firstName: '',
      lastName: '',
      legalEntityName: '',
      nickname: '',
      payoutDescriptor: '',
    };

    return (
      <NewAccountsForm
        creatingOnboardingLink={ creatingOnboardingLink }
        initialValues={ initialValues }
        division={ division }
        onSubmit={ (values) => submitPaymentAccount(values) }
      />
    );
  }
}

AccountsNewForm.propTypes = {
  submitPaymentAccount: PropTypes.func.isRequired,
};

export default AccountsNewForm;
