import { postIdNumber } from 'services/payments';

// ----------------------------------------------------------------------------------------------------
// :: Action Types
// ----------------------------------------------------------------------------------------------------
export const ID_NUMBER = 'ID_NUMBER';
const FETCH_ID_NUMBER = 'FETCH_ID_NUMBER';
const ERROR_ID_NUMBER = 'ERROR_ID_NUMBER';
const UPDATE_ID_NUMBER = 'SAVE_ID_NUMBER';

// ----------------------------------------------------------------------------------------------------
// :: Action Creators
// ----------------------------------------------------------------------------------------------------
export const updateIdNumber = (paymentAccountId, idNumber) => async dispatch => {
  try {
    const payload = { paymentAccountId, idNumber };
    dispatch({ type: FETCH_ID_NUMBER, payload });
    await postIdNumber(paymentAccountId, idNumber);
    dispatch({ type: UPDATE_ID_NUMBER, payload });
  } catch (error) {
    dispatch({ type: ERROR_ID_NUMBER, payload: error });
    return error;
  }
};
