import IdNumberForm from 'components/baseComponents/IdNumberForm';
import { historyPropShape, matchPropShape } from 'interfaces/navigation';
import { paymentAccountPropShape } from 'interfaces/paymentAccount';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { reduxForm } from 'redux-form';
import { tokenizePersonalId } from 'services/stripe';
import { formatErrorMessage } from 'utils/formatter';
import { linkToAccounts } from 'utils/links';

class IdNumberUpdate extends Component {
  submitIdNumber = async (values) => {
    const response = await tokenizePersonalId(values.idNumber.trim());

    if (response?.error) {
      return this.errorForm(response?.error);
    }

    const idNumber = response.token.id;

    const { paymentAccount } = this.props;

    await this.props.updateIdNumber(paymentAccount.id, idNumber);

    this.props.showFlashMessage({
      autoClose: true,
      icon: 'check',
      color: 'positive',
      title: 'Success',
      message: 'The SSN has been updated',
      handleClose: this.props.hideFlashMessage,
    });

    this.handleRedirectToAccounts();
  };

  errorForm = (error) => {
    this.props.showFlashMessage({
      autoClose: false,
      icon: 'dismiss',
      title: 'Submit Failed',
      message: formatErrorMessage(error.message),
      color: 'negative',
      handleClose: this.props.hideFlashMessage,
    });
  };

  handleRedirectToAccounts = () => {
    const {
      history,
      match: { params },
    } = this.props;
    return history.push(linkToAccounts(params));
  };

  render() {
    const { paymentAccount } = this.props;

    return (
      <IdNumberForm paymentAccount={ paymentAccount } onSubmit={ this.submitIdNumber } />
    );
  }
}

IdNumberUpdate.propTypes = {
  updateIdNumber: PropTypes.func.isRequired,
  showFlashMessage: PropTypes.func.isRequired,
  hideFlashMessage: PropTypes.func.isRequired,
  history: PropTypes.shape(historyPropShape).isRequired,
  match: PropTypes.shape(matchPropShape).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  paymentAccount: PropTypes.shape(paymentAccountPropShape),
  submitting: PropTypes.bool.isRequired,
  requestError: PropTypes.shape({}),
  idNumberData: PropTypes.shape({}),
};

IdNumberUpdate.defaultProps = {
  paymentAccount: null,
  requestError: null,
  idNumberData: null,
};

export default reduxForm({ form: 'idNumberUpdate' })(IdNumberUpdate);
