import { api } from './api';

const endpoints = {
  paymentAccounts: '/v2/payment_accounts',
  createOnboarding: '/v2/payment_accounts/:accountId/create_onboarding_link',
};

const getEndpoint = (name, params) => endpoints[name].replace(':accountId', params.accountId);

export const getPaymentAccounts = (params) => api.get(endpoints.paymentAccounts, params);

export const postCreatePaymentAccount = (data) => api.post(endpoints.paymentAccounts, data);

export const postCreateOnboardingLink = (accountId, data) => api.post(getEndpoint('createOnboarding', { accountId }), data);
