import PropTypes from 'prop-types';

export const paymentAccountPropShape = {
  account: PropTypes.shape({
    address: PropTypes.shape({
      city: PropTypes.string,
      country: PropTypes.string,
      line1: PropTypes.string,
      line2: PropTypes.string,
      postalCode: PropTypes.string,
      state: PropTypes.string
    }),
    businessType: PropTypes.string,
    company: PropTypes.shape({
      name: PropTypes.string,
      taxIdProvided: PropTypes.bool
    }),
    country: PropTypes.string,
    currency: PropTypes.string,
    isSetupComplete: PropTypes.bool,
    stripesAccountId: PropTypes.string
  }),
  person: PropTypes.shape({
    dob: PropTypes.shape({
      day: PropTypes.number,
      month: PropTypes.number,
      year: PropTypes.number
    }),
    isSetupComplete: PropTypes.bool,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    id: PropTypes.string,
    idNumberProvided: PropTypes.bool
  }),
  bankAccount: PropTypes.shape({
    isSetupComplete: PropTypes.bool,
    last4: PropTypes.string,
    routingNumber: PropTypes.string,
    currency: PropTypes.string,
    country: PropTypes.string
  }),
  isSetupComplete: PropTypes.bool,
  id: PropTypes.string
};
