import AcceptTermsForm from 'components/baseComponents/AcceptTermsForm';
import { historyPropShape, matchPropShape } from 'interfaces/navigation';
import { paymentAccountPropShape } from 'interfaces/paymentAccount';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { reduxForm } from 'redux-form';
import { formatErrorMessage } from 'utils/formatter';
import { linkToAccounts } from 'utils/links';

class AcceptTerms extends Component {
  errorForm = (error) => {
    this.props.showFlashMessage({
      autoClose: false,
      icon: 'dismiss',
      title: 'Submit Failed',
      message: formatErrorMessage(error.message),
      color: 'negative',
      handleClose: this.props.hideFlashMessage,
    });
  };

  handleRedirectToAccounts = () => {
    const {
      history,
      match: { params },
    } = this.props;
    return history.push(linkToAccounts(params));
  };

  doAcceptTerms = async (values) => {
    const { paymentAccount } = this.props;

    const error = await this.props.acceptTerms(paymentAccount.id);

    if(error) {
      return this.errorForm(error);
    } else {
      this.props.showFlashMessage({
        autoClose: true,
        icon: 'check',
        color: 'positive',
        title: 'Success',
        message: 'The Terms of Service have been accepted',
        handleClose: this.props.hideFlashMessage,
      });
      
      this.handleRedirectToAccounts();
    }
  };

  render() {
    const { paymentAccount } = this.props;

    return (
      <AcceptTermsForm paymentAccount={ paymentAccount } onSubmit={ this.doAcceptTerms } />
    );
  }
}

AcceptTerms.propTypes = {
  acceptTerms: PropTypes.func.isRequired,
  showFlashMessage: PropTypes.func.isRequired,
  hideFlashMessage: PropTypes.func.isRequired,
  history: PropTypes.shape(historyPropShape).isRequired,
  match: PropTypes.shape(matchPropShape).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  paymentAccount: PropTypes.shape(paymentAccountPropShape),
  submitting: PropTypes.bool.isRequired,
  requestError: PropTypes.shape({}),
  acceptTermsData: PropTypes.shape({}),
};

AcceptTerms.defaultProps = {
  paymentAccount: null,
  requestError: null,
  acceptTermsData: null,
};

export default reduxForm({ form: 'AcceptTerms' })(AcceptTerms);
