import { getMe } from 'services/users';

// ----------------------------------------------------------------------------------------------------
// :: Action Types
// ----------------------------------------------------------------------------------------------------
export const USER = 'USER';
const FETCH_USER = 'FETCH_USER';
const SAVE_USER = 'SAVE_USER';
const ERROR_USER = 'ERROR_USER';

// ----------------------------------------------------------------------------------------------------
// :: Action Creators
// ----------------------------------------------------------------------------------------------------
export const fetchUser = () => async dispatch => {
  dispatch({ type: FETCH_USER });
  try {
    const response = await getMe();
    return dispatch({ type: SAVE_USER, payload: response.data[0] });
  } catch (error) {
    dispatch({ type: ERROR_USER, payload: error });
    return Promise.reject(error);
  }
};
