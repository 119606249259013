import { getPayouts, getPayout } from 'services/payments';
import { selectPaymentAccountId } from 'state/paymentAccounts/selectors';
import { arrayToObject } from 'utils/helpers';

// ----------------------------------------------------------------------------------------------------
// :: Action Types
// ----------------------------------------------------------------------------------------------------
export const PAYOUTS = 'PAYOUTS';
const FETCH_PAYOUTS = 'FETCH_PAYOUTS';
const SAVE_PAYOUTS = 'SAVE_PAYOUTS';
const SAVE_NEXT_PAYOUTS = 'SAVE_NEXT_PAYOUTS';
const SAVE_PREV_PAYOUTS = 'SAVE_PREV_PAYOUTS';
const ERROR_PAYOUTS = 'ERROR_PAYOUTS';
const CLEAR_PAYOUTS = 'CLEAR_PAYOUTS';

// ----------------------------------------------------------------------------------------------------
// :: Action Creators
// ----------------------------------------------------------------------------------------------------
export const getPaymentPayouts = (params) => getPayouts({ limit: 50, ...params });

const saveFetchPayouts = (actionType, response) => {
  const filtered = response.data.length > 0 ?
    response.data.filter((_item, index) => index === 0 || index === response.data.length - 1).map(x => x.id) : [];

  const paginate = { endingBefore: filtered[0], startingAfter: filtered[1] };

  const mappedData = arrayToObject(response.data, 'id');

  return {
    type: actionType,
    payload: { paginate, data: mappedData, meta: response.meta }
  };
};

export const fetchPayout = (payoutId, params = {}) => async (dispatch, getState) => {
  dispatch({ type: FETCH_PAYOUTS });
  const accountId = selectPaymentAccountId(getState());

  try {
    const response = await getPayout({ accountId, payoutId, ...params });
    return dispatch(saveFetchPayouts(SAVE_PAYOUTS, response));
  } catch (error) {
    dispatch({ type: ERROR_PAYOUTS, payload: error });
    return Promise.reject(error);
  }
};

export const fetchPayouts = (params = {}) => async (dispatch, getState) => {
  dispatch({ type: FETCH_PAYOUTS });
  const accountId = selectPaymentAccountId(getState());

  try {
    const response = await getPaymentPayouts({ accountId, ...params });
    return dispatch(saveFetchPayouts(SAVE_PAYOUTS, response));
  } catch (error) {
    dispatch({ type: ERROR_PAYOUTS, payload: error });
    return Promise.reject(error);
  }
};

export const fetchNextPayouts = (params = {}) => async (dispatch, getState) => {
  dispatch({ type: FETCH_PAYOUTS });
  const accountId = selectPaymentAccountId(getState());
  const { startingAfter, hasNext } = getState().payouts.paginate;
  const pageParams = hasNext ? { startingAfter } : {};

  try {
    const response = await getPaymentPayouts({ accountId, ...pageParams, ...params });
    return dispatch(saveFetchPayouts(SAVE_NEXT_PAYOUTS, response));
  } catch (error) {
    dispatch({ type: ERROR_PAYOUTS, payload: error });
    return Promise.reject(error);
  }
};

export const fetchPrevPayouts = (params = {}) => async (dispatch, getState) => {
  dispatch({ type: FETCH_PAYOUTS });
  const accountId = selectPaymentAccountId(getState());
  const { endingBefore, hasPrev } = getState().payouts.paginate;
  const pageParams = hasPrev ? { endingBefore } : {};

  try {
    const response = await getPaymentPayouts({ accountId, ...pageParams, ...params });
    return dispatch(saveFetchPayouts(SAVE_PREV_PAYOUTS, response));
  } catch (error) {
    dispatch({ type: ERROR_PAYOUTS, payload: error });
    return Promise.reject(error);
  }
};

export const clearPayouts = () => dispatch => {
  dispatch({ type: CLEAR_PAYOUTS });
};
