import React from 'react';
import PropTypes from 'prop-types';
import { FieldMessage } from '@teamsnap/teamsnap-ui';

const FieldError = props => (
  <FieldMessage isError>
    { props.children }
  </FieldMessage>
);

FieldError.propTypes = {
  children: PropTypes.node
};

FieldError.defaultProps = {
  children: null
};

export default FieldError;
