import PropTypes from 'prop-types';

export const payoutPropShape = {
  amount: PropTypes.number,
  amountWithCurrency: PropTypes.string,
  arrivalDate: PropTypes.string,
  currency: PropTypes.string,
  description: PropTypes.string,
  destination: PropTypes.shape({
    account: PropTypes.string,
    country: PropTypes.string,
    currency: PropTypes.string,
    id: PropTypes.string,
    last4: PropTypes.string,
    object: PropTypes.string,
    routingNumber: PropTypes.string,
    status: PropTypes.string
  }),
  id: PropTypes.string,
  resourceType: PropTypes.string,
  status: PropTypes.string,
  stripesPayoutId: PropTypes.string
};
