import { getPaymentAccountsBy, patchPaymentAccount, patchPaymentAccountWithFormFields } from 'services/payments';
import { getPaymentAccounts, postCreatePaymentAccount } from 'services/paymentsV2';

import { ChurnZeroService } from 'frontend-toolkit';
// ----------------------------------------------------------------------------------------------------
// :: Action Types
// ----------------------------------------------------------------------------------------------------
export const PAYMENT_ACCOUNT = 'PAYMENT_ACCOUNT';
const ERROR_PAYMENT_ACCOUNT = 'ERROR_PAYMENT_ACCOUNT';
const SAVE_PAYMENT_ACCOUNT = 'SAVE_PAYMENT_ACCOUNT';
const FETCH_PAYMENT_ACCOUNT = 'FETCH_PAYMENT_ACCOUNT';
const UPDATE_PAYMENT_ACCOUNT = 'UPDATE_PAYMENT_ACCOUNT';

// ----------------------------------------------------------------------------------------------------
// :: Action Creators
// ----------------------------------------------------------------------------------------------------
export const fetchPaymentAccountsByDivision = (divisionId) => async (dispatch, getState) => {
  dispatch({ type: FETCH_PAYMENT_ACCOUNT });
  try {
    const response = await getPaymentAccountsBy({ divisionId, is_active: true });
    return dispatch({
      type: SAVE_PAYMENT_ACCOUNT,
      payload: response.data[0],
    });
  } catch (error) {
    dispatch({ type: ERROR_PAYMENT_ACCOUNT, payload: error });
    return error;
  }
};

export const fetchPaymentAccount = (divisionPersistentUuid, divisionId) => async (dispatch, getState) => {
  dispatch({ type: FETCH_PAYMENT_ACCOUNT });
  try {
    const { data } = await getPaymentAccounts({ scope_id: divisionPersistentUuid, scope_type: 'division', divisionId: divisionId });
    let paymentAccount = getState().paymentAccounts.data;

    if (!data && !paymentAccount) {
      return dispatch({
        type: SAVE_PAYMENT_ACCOUNT,
        payload: null,
      });
    }

    paymentAccount = {
      ...paymentAccount,
      ...data,
      id: paymentAccount.id,
      paymentsServiceId: data && data.id ? data.id : null,
    };

    return dispatch({
      type: SAVE_PAYMENT_ACCOUNT,
      payload: paymentAccount,
    });
  } catch (error) {
    dispatch({ type: ERROR_PAYMENT_ACCOUNT, payload: error });
    return error;
  }
};

export const fetchPaymentAccountsByTeam = (teamId) => async (dispatch) => {
  dispatch({ type: FETCH_PAYMENT_ACCOUNT });
  try {
    const response = await getPaymentAccountsBy({ teamId, is_active: 'true' });
    return dispatch({ type: SAVE_PAYMENT_ACCOUNT, payload: response.data[0] });
  } catch (error) {
    dispatch({ type: ERROR_PAYMENT_ACCOUNT, payload: error });
    return error;
  }
};

export const updatePaymentAccount = (paymentAccountId, data) => async (dispatch) => {
  try {
    const response = await patchPaymentAccount(paymentAccountId, data);
    const nestedError = [];
    for (const key in response.data) {
      if (response.data[key] && response.data[key].message) {
        nestedError.push(response.data[key].message);
      }
    }
    if (nestedError.length > 0) {
      const errorMessage = { message: nestedError.join('\r\n') };
      return dispatch({ type: ERROR_PAYMENT_ACCOUNT, payload: { error: errorMessage } });
    } else {
      return dispatch({ type: UPDATE_PAYMENT_ACCOUNT, payload: response.data });
    }
  } catch (error) {
    dispatch({ type: ERROR_PAYMENT_ACCOUNT, payload: error });
    return error;
  }
};

// If we need to keep both, extract dupe code from this and above function
export const updatePaymentAccountWithFormFields = (paymentAccountId, data) => async (dispatch) => {
  try {
    const response = await patchPaymentAccountWithFormFields(paymentAccountId, data);
    const errors = [];
    if (response.error) errors.push(response.error);
    for (const key in response.data) {
      if (response.data && response.data[key] && response.data[key].message) {
        errors.push(response.data[key].message);
      }
    }
    if (errors.length > 0) {
      const errorMessage = { message: errors.join('\r\n') };
      return dispatch({ type: ERROR_PAYMENT_ACCOUNT, payload: { error: errorMessage } });
    } else {
      ChurnZeroService.trackChurnZeroEvent('Stripe financial settings updated');
      return dispatch({ type: UPDATE_PAYMENT_ACCOUNT, payload: response.data });
    }
  } catch (error) {
    dispatch({ type: ERROR_PAYMENT_ACCOUNT, payload: error });
    return error;
  }
};

export const createNewPaymentAccount = (data) => async (dispatch) => {
  try {
    const response = await postCreatePaymentAccount(data);
    return dispatch({ type: UPDATE_PAYMENT_ACCOUNT, payload: response.data });
  } catch (error) {
    dispatch({ type: ERROR_PAYMENT_ACCOUNT, payload: error });
    return error;
  }
};
