import { getOutstandingPayments } from 'services/payments';

// ----------------------------------------------------------------------------------------------------
// :: Action Types
// ----------------------------------------------------------------------------------------------------
export const OUTSTANDING_PAYMENTS = 'OUTSTANDING_PAYMENTS';
export const FETCH_OUTSTANDING_PAYMENTS = 'FETCH_OUTSTANDING_PAYMENTS';
const ERROR_OUTSTANDING_PAYMENTS = 'ERROR_OUTSTANDING_PAYMENTS';
const SAVE_OUTSTANDING_PAYMENTS = 'SAVE_OUTSTANDING_PAYMENTS';

// ----------------------------------------------------------------------------------------------------
// :: Action Creators
// ----------------------------------------------------------------------------------------------------
export const fetchOutstandingPaymentsByDivision = (divisionId) =>
  async (dispatch, getState) => {
    dispatch({ type: FETCH_OUTSTANDING_PAYMENTS });

    try {
      const response = await getOutstandingPayments(divisionId);
      return dispatch({
        type: SAVE_OUTSTANDING_PAYMENTS,
        payload: {
          data: response.data,
          meta: response.meta,
          request: response.request
        }
      });
    } catch (error) {
      dispatch({ type: ERROR_OUTSTANDING_PAYMENTS, payload: error });
      return error;
    }
  };
