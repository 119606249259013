import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FieldInput from 'components/teamsnapUIExtensions/FieldInput';
import { Cell, FieldLabel, Grid } from '@teamsnap/teamsnap-ui';
import { determineFieldSize } from 'utils/formFields';
import { fieldPropShape } from 'interfaces/field';
import { paymentAccountPropShape } from 'interfaces/paymentAccount';
import { validate } from './validationBuilder';

class FieldBuilder extends Component {
  validator = (value, allValues, _props, name) => {
    const { paymentAccount, field } = this.props;
    return validate(value, paymentAccount, name, field.isRequired, allValues);
  }

  render () {
    const {
      field,
      initialValues,
      isEditing
    } = this.props;

    const { displayType, formattedName } = field;

    return (
      <>
        { displayType === 'confirm' &&
          <>
            <Cell mods='u-size3of4'>
              <FieldInput
                name={ formattedName }
                initialValues={ initialValues }
                label={ field.label }
                validate={ this.validator }
                toolTipText={ field.helpInfo }
                isEditing={ isEditing }
                placeholder={ field.placeholder }
              />
            </Cell>
            { isEditing &&
              <Cell mods='u-size3of4'>
                <FieldInput
                  name={ `${formattedName}2` }
                  initialValues={ initialValues }
                  label={ `${field.label}` }
                  validate={ this.validator }
                  toolTipText={ field.helpInfo }
                  isEditing={ isEditing }
                  placeholder={ field.placeholder }
                  subtitle='(confirm)'
                />
              </Cell> }
          </> }

        { displayType === 'date' &&
          <Cell mods='u-size1of1'>
            <FieldLabel>{ field.label }</FieldLabel>
            <Grid isWithGutter>
              <Cell mods='u-size1of3'>
                <FieldInput
                  name={ `${formattedName}Month` }
                  validate={ this.validator }
                  initialValues={ initialValues }
                  type='monthSelect'
                  isEditing={ isEditing }
                />
              </Cell>
              <Cell mods='u-size1of3'>
                <FieldInput
                  name={ `${formattedName}Day` }
                  validate={ this.validator }
                  initialValues={ initialValues }
                  type='daySelect'
                  isEditing={ isEditing }
                />
              </Cell>
              <Cell mods='u-size1of3'>
                <FieldInput
                  name={ `${formattedName}Year` }
                  validate={ this.validator }
                  initialValues={ initialValues }
                  type='yearSelect'
                  isEditing={ isEditing }
                />
              </Cell>
            </Grid>
          </Cell> }

        { displayType === 'state' &&
          <Cell mods='u-size1of4'>
            <FieldInput
              name={ formattedName }
              initialValues={ initialValues }
              label={ field.label }
              validate={ this.validator }
              isEditing={ isEditing }
              toolTipText={ field.helpInfo }
              type={ field.label === 'Province' ? 'provinceSelect' : 'stateSelect' }
            />
          </Cell> }

        { displayType === 'breakpoint' &&
          <>
            <Cell mods='u-size1of1'>
              <FieldInput
                name={ formattedName }
                initialValues={ initialValues }
                label={ field.label }
                validate={ this.validator }
                isEditing={ isEditing }
                toolTipText={ field.helpInfo }
                placeholder={ field.placeholder }
                fieldWidth={ formattedName.match(/city/i) ? '75%' : '25%' }
              />
            </Cell>
          </> }

        { displayType === 'normal' &&
          <Cell mods={ determineFieldSize(formattedName) }>
            <FieldInput
              name={ formattedName }
              initialValues={ initialValues }
              label={ field.label }
              validate={ this.validator }
              isEditing={ isEditing }
              toolTipText={ field.helpInfo }
              placeholder={ field.placeholder }
            />
          </Cell> }
      </>
    );
  }
}

FieldBuilder.propTypes = {
  field: PropTypes.shape(fieldPropShape).isRequired,
  isEditing: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  paymentAccount: PropTypes.shape(paymentAccountPropShape).isRequired
};

export default FieldBuilder;
