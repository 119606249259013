/**
 * Lodash Helper Functions
 *  Lodash function wrappers for easier migration in the future.
 *
 */

import _snakeCase from 'lodash/snakeCase';
import _camelCase from 'lodash/camelCase';
import _startCase from 'lodash/startCase';
import _reduce from 'lodash/reduce';
import _range from 'lodash/range';

export const snakeCase = (string) => _snakeCase(string);
export const camelCase = (string) => _camelCase(string);
export const startCase = (string) => _startCase(string);

export const reduce = (object, callback, initial = {}) => _reduce(object, callback, initial);

export const range = (start, stop, step = 1) => _range(start, stop, step);
