/**
 * Portals are useful for mounting elements that needs to appear outside the apps normal dom.
 *  Modals and Feedback components are common use cases.
 */

import { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

class PortalRoot extends PureComponent {
  element = document.createElement('div')

  componentDidMount () {
    document.body.appendChild(this.element);
  }

  componentWillUnmount () {
    document.body.removeChild(this.element);
  }

  render () {
    return ReactDOM.createPortal(this.props.children, this.element);
  }
}

PortalRoot.propTypes = {
  children: PropTypes.node
};

PortalRoot.defaultProps = {
  children: null
};

export default PortalRoot;
