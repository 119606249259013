import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@teamsnap/teamsnap-ui';
import LoaderModal from 'components/teamsnapUIExtensions/LoaderModal';
import { convertToCSV } from 'utils/formatter';
import { getEndOfDay, getStartOfDay, getThirtyDaysAgo } from 'utils/date';

class ExportButton extends Component {
  constructor (props) {
    super(props);
    this.state = {
      rowsInState: [],
      showModal: false,
      userClickedCancel: false
    };
    this.csvTag = React.createRef();
  }

  handleClick = () => {
    this.setState({
      showModal: true,
      userClickedCancel: false
    }, this.fetchExportData);
  }

  fetchExportData = async () => {
    const {
      fetchAllCharges,
      fetchAllPayouts,
      fetchAllPayoutTransactions,
      formatRows,
      dataType,
      payoutId,
      startDate,
      endDate
    } = this.props;

    const dataTypeToFunction = {
      charges: fetchAllCharges,
      payouts: fetchAllPayouts,
      payoutTransactions: (params = {}) => fetchAllPayoutTransactions(payoutId, params)
    };

    const createdGte = this.getCreatedGte(startDate, dataType);
    const createdLte = this.getCreatedLte(endDate, dataType);

    const fetch = dataTypeToFunction[dataType];
    const response = await fetch({ createdGte, createdLte });
    const formattedRows = response.payload.data && formatRows(response.payload.data);
    this.setState({
      rowsInState: formattedRows,
      showModal: false
    }, this.handleCSVDownload);
  }

  getCreatedGte = (startDate, dataType) => {
    if (['charges', 'payouts'].includes(dataType) && !startDate) {
      return getStartOfDay(getThirtyDaysAgo());
    } else if (!startDate) {
      return null;
    } else {
      return getStartOfDay(startDate);
    }
  }

  getCreatedLte = (endDate, dataType) => {
    if (['charges', 'payouts'].includes(dataType) && !endDate) {
      return getEndOfDay(new Date());
    } else if (!endDate) {
      return null;
    } else {
      return getEndOfDay(endDate);
    }
  }

  handleCSVDownload = () => {
    if (this.state.userClickedCancel === false) {
      this.csvTag.current.click();
    }
  }

  componentDidMount = () => {
    this.setState({
      rowsInState: this.props.rows
    });
  }

  onCloseModal = () => {
    this.setState({
      showModal: false,
      userClickedCancel: true
    });
  }

  render () {
    const { rowsInState, showModal } = this.state;
    const { rows, dataIsLoading, columns } = this.props;
    const csv = '\ufeff' + convertToCSV(columns, rowsInState);
    const blob = new Blob([csv], { encoding: 'UTF-8', type: 'text/csv;charset=UTF-8' });
    const csvData = window.URL.createObjectURL(blob);

    return (
      <>
        <Button
          isDisabled={ rows.length === 0 || dataIsLoading }
          onClick={ this.handleClick }
        >
          { dataIsLoading ? 'Please Wait...' : 'Export to CSV' }
        </Button>
        <a
          style={ { display: 'none' } }
          href={ rows.length === 0 ? undefined : csvData }
          download={ rows.length === 0 ? false : `reporting_${Date.now()}.csv` }
          ref={ this.csvTag }
        >
          Download
        </a>
        <LoaderModal
          show={ showModal }
          handleClose={ this.onCloseModal }
        />
      </>
    );
  }
}

ExportButton.propTypes = {
  columns: PropTypes.array.isRequired,
  formatRows: PropTypes.func.isRequired,
  dataType: PropTypes.string.isRequired,
  rows: PropTypes.array.isRequired,
  fetchAllCharges: PropTypes.func.isRequired,
  fetchAllPayouts: PropTypes.func.isRequired,
  fetchAllPayoutTransactions: PropTypes.func.isRequired,
  dataIsLoading: PropTypes.bool,
  payoutId: PropTypes.string,
  startDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  endDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ])
};

ExportButton.defaultProps = {
  dataIsLoading: false,
  payoutId: null,
  startDate: null,
  endDate: null
};

export default ExportButton;
