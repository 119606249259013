import { FieldGroup, FieldLabel, Icon, Tooltip } from '@teamsnap/teamsnap-ui';
import DaySelect from 'components/teamsnapUIExtensions/Fields/DaySelect';
import Input from 'components/teamsnapUIExtensions/Fields/Input';
import MonthSelect from 'components/teamsnapUIExtensions/Fields/MonthSelect';
import ProvinceSelect from 'components/teamsnapUIExtensions/Fields/ProvinceSelect';
import Select from 'components/teamsnapUIExtensions/Fields/Select';
import StateSelect from 'components/teamsnapUIExtensions/Fields/StateSelect';
import YearSelect from 'components/teamsnapUIExtensions/Fields/YearSelect';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

const FieldInput = (props) => {
  const {
    name,
    type,
    label,
    placeholder,
    initialValues,
    isEditing,
    toolTipText,
    fieldWidth,
    subtitle,
    validate
  } = props;

  const FieldTypes = {
    input: Input,
    select: Select,
    daySelect: DaySelect,
    monthSelect: MonthSelect,
    yearSelect: YearSelect,
    stateSelect: StateSelect,
    provinceSelect: ProvinceSelect
  };

  const FieldTag = FieldTypes[type];

  const initialValue = initialValues[name];

  return (
    <FieldGroup>
      { label &&
        <FieldLabel>
          { label }
          { subtitle && <span style={ { marginLeft: '5px' } } className='u-colorGrey'>{ subtitle }</span> }
          { isEditing && toolTipText &&
            <Tooltip text={ toolTipText } style={ { wordWrap: 'break-word' } } type='icon' mods='u-padXs'>
              <Icon name='info' />
            </Tooltip> }
        </FieldLabel> }
      { !isEditing && <span>{ initialValue }</span> }
      { isEditing &&
        <>
          <Field
            component={ FieldTag }
            validate={ validate }
            fieldWidth={ fieldWidth }
            name={ name }
            placeholder={ placeholder === null ? label : placeholder }
          />
        </> }
    </FieldGroup>
  );
};

FieldInput.propTypes = {
  name: PropTypes.string.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  isEditing: PropTypes.bool,
  toolTipText: PropTypes.string,
  fieldWidth: PropTypes.string,
  subtitle: PropTypes.string,
  validate: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.func
  ])
};

FieldInput.defaultProps = {
  type: 'input',
  label: null,
  placeholder: null,
  isEditing: true,
  toolTipText: '',
  fieldWidth: '100%',
  subtitle: '',
  validate: null
};

export default FieldInput;
