import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Cell, Panel } from '@teamsnap/teamsnap-ui';
import { tokenizeBankAccount, tokenizePersonalId } from 'services/stripe';
import { linkToAccounts, linkToAccountsNew } from 'utils/links';
import AccountsFormBuilder from 'components/baseComponents/AccountsFormBuilder';
import { combineFormFields, formatFormFieldName } from 'utils/formFields';
import { paymentAccountPropShape } from 'interfaces/paymentAccount';
import { matchPropShape, historyPropShape } from 'interfaces/navigation';
import { getPaddedDateString } from 'utils/date';
import { SubmissionError } from 'redux-form';
import { formatErrorMessage } from 'utils/formatter';


class AccountsEdit extends Component {
  componentDidMount() {
    const {
      history,
      paymentAccount,
      match: { params },
    } = this.props;

    if (!paymentAccount) {
      history.push(linkToAccountsNew({ ...params }));
    }
  }

  verifyAge = (dob) => {
    const birthdate = new Date(dob).getTime();
    const currentYear = new Date().getFullYear();
    const eighteenYearsAgo = new Date().toString().replace(currentYear, currentYear - 18);
    return new Date(eighteenYearsAgo).getTime() >= birthdate;
  };

  submitPaymentAccount = async (values) => {
    const { paymentAccount, formFieldsBySection } = this.props;
    if (values.dobYear) {
      const dob = getPaddedDateString(values.dobYear, values.dobMonth, values.dobDay);
      if (!this.verifyAge(dob)) {
        throw new SubmissionError({ dobYear: 'You must be over 18 to use TeamSnap Payments.' });
      }
    }

    let response = null;
    if (values.idNumber && values.routingNumber) {
      response = await Promise.all([
        tokenizeBankAccount({
          routing_number: values.routingNumber.trim(),
          account_number: values.accountNumber.trim(),
          country: paymentAccount.account.country,
          currency: paymentAccount.account.currency,
        }),
        tokenizePersonalId(values.idNumber.trim()),
      ]);
    } else if (values.routingNumber) {
      response = await Promise.all([
        tokenizeBankAccount({
          routing_number: values.routingNumber.trim(),
          account_number: values.accountNumber.trim(),
          country: paymentAccount.account.country,
          currency: paymentAccount.account.currency,
        }),
      ]);
    } else if (values.idNumber) {
      response = await Promise.all([tokenizePersonalId(values.idNumber.trim())]);
    }

    const error = response && response.find((r) => r.error);
    if (error) {
      return this.errorForm(error.error);
    }

    const combinedFields = combineFormFields(formFieldsBySection);
    const attributes = {
      ...(values.routingNumber && {
        'external_account.external_account': response.find((r) => r.token.type === 'bank_account').token.id,
      }),
    };

    combinedFields
      .filter((field) => !field.name.includes('external_account'))
      .forEach((field) => {
        if (field.name.includes('dob')) {
          attributes[field.name] = getPaddedDateString(values.dobYear, values.dobMonth, values.dobDay);
        } else if (field.name.includes('id_number') && values.idNumber) {
          attributes[field.name] = response.find((r) => r.token.type === 'pii').token.id;
        } else if (values[formatFormFieldName(field.name)]) {
          attributes[field.name] = values[formatFormFieldName(field.name)];
        }
      });

    return this.submitForm({ attributes });
  };

  submitForm = async (payload) => {
    const { paymentAccount } = this.props;

    const paymentAccountResponse = await this.props.updatePaymentAccountWithFormFields(paymentAccount.id, payload);

    if (paymentAccountResponse && paymentAccountResponse.error) {
      return this.errorForm(paymentAccountResponse.error);
    }
    if (paymentAccountResponse && paymentAccountResponse.payload && paymentAccountResponse.payload.error) {
      return this.errorForm(paymentAccountResponse.payload.error);
    }

    const financialsBeacon = document.querySelector('.js-active--pulseBadge');
    if (financialsBeacon && financialsBeacon.innerText.match('Financials')) {
      financialsBeacon.removeAttribute('class');
    }

    const formFieldResponse = await this.props.fetchFormFields(paymentAccount.id);
    if (formFieldResponse && formFieldResponse.error) {
      return this.errorForm(formFieldResponse.payload.error);
    }

    this.props.showFlashMessage({
      autoClose: true,
      icon: 'check',
      color: 'positive',
      title: 'Success',
      message: 'The account settings have been updated',
      handleClose: this.props.hideFlashMessage,
    });

    this.handleRedirectToAccounts(paymentAccountResponse);
  };

  errorForm = (error) => {
    this.props.showFlashMessage({
      autoClose: false,
      icon: 'dismiss',
      title: 'Submit Failed',
      message: formatErrorMessage(error.message),
      color: 'negative',
      handleClose: this.props.hideFlashMessage,
    });

    throw new SubmissionError({ _error: 'Submit Failed' });
  };

  handleRedirectToAccounts = (response) => {
    const {
      history,
      match: { params },
    } = this.props;
    if (!response || response.payload.isSetupComplete) {
      return history.push(linkToAccounts(params));
    } else {
      return window.location.assign(linkToAccounts(params));
    }
  };

  render() {
    const { paymentAccount, formFieldsBySection } = this.props;

    return (
      <div className={ !paymentAccount.isSetupComplete ? 'u-flexInline' : null }>
        <Panel style={ { marginTop: '20px', width: '48%', marginLeft: 'auto', marginRight: 'auto' } }>
          <Grid isWithGutter isAlignMiddle>
            <Cell mods="u-size1of1" style={ { padding: '0 30px' } }>
              <AccountsFormBuilder
                paymentAccount={ paymentAccount }
                submitPaymentAccount={ this.submitPaymentAccount }
                onCancel={ () => this.handleRedirectToAccounts() }
                isEditing
                formFieldsBySection={ formFieldsBySection }
              />
            </Cell>
          </Grid>
        </Panel>
      </div>
    );
  }
}

AccountsEdit.propTypes = {
  updatePaymentAccountWithFormFields: PropTypes.func.isRequired,
  showFlashMessage: PropTypes.func.isRequired,
  hideFlashMessage: PropTypes.func.isRequired,
  history: PropTypes.shape(historyPropShape).isRequired,
  match: PropTypes.shape(matchPropShape).isRequired,
  paymentAccount: PropTypes.shape(paymentAccountPropShape),
  requestError: PropTypes.shape({}),
  formFieldsBySection: PropTypes.array,
  fetchFormFields: PropTypes.func.isRequired
};

AccountsEdit.defaultProps = {
  paymentAccount: null,
  requestError: null,
  formFieldsBySection: []
};

export default AccountsEdit;
