import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Loader, Button } from '@teamsnap/teamsnap-ui';
import './LoaderModal.css';

class LoaderModal extends Component {
  render () {
    const { show, handleClose } = this.props;

    return (
      <div className={ `pf-Modal ${show ? 'pf-Modal-open' : 'pf-Modal-closed'}` } data-modal='modal-one'>
        <div className='pf-Modal-content'>
          <div className='pf-Modal-header'>
            <h2 style={ { marginTop: '20px' } }>Building Report</h2>
          </div>
          <div className='pf-Modal-body'>
            <Loader
              type='spin'
              text='Loading...'
              message='Standby... our elves are building your report. This will take a little bit, get yourself a glass of water and stay hydrated.'
            />
            <div className='pf-Modal-footer'>
              <Button
                color='negative'
                onClick={ handleClose }
                icon='dismiss'
                className='ga-report-cancel'
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

LoaderModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default LoaderModal;
