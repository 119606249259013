import PropTypes from 'prop-types';

export const fieldPropShape = {
  confirm: PropTypes.bool,
  displayType: PropTypes.string,
  formattedName: PropTypes.string,
  helpInfo: PropTypes.string,
  inputType: PropTypes.string,
  isRequired: PropTypes.bool,
  isSensitive: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  order: PropTypes.number,
  placeholder: PropTypes.string
};

export const metaPropShape = {
  error: PropTypes.string,
  touched: PropTypes.bool
};
