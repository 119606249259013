import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Grid, Cell, Loader, Button } from '@teamsnap/teamsnap-ui';
import { routes, linkToRoot, linkToPayments, linkToAccountsEdit, useTeamsAccounts, linkToTeamsAccounts } from 'utils/links';
import FlashMessage from 'components/baseComponents/FlashMessage';
import ConnectedBanner from 'components/baseComponents/FlashMessage/ConnectedBanner';
import Accounts from 'components/screens/Accounts';
import AccountsEdit from 'components/screens/AccountsEdit';
import Payments from 'components/screens/Payments';
import Deposits from 'components/screens/Deposits';
import DepositsShow from 'components/screens/DepositsShow';
import { Link, matchPath } from 'react-router-dom';
import AppRoutes from 'components/baseComponents/AppRoutes';
import { paymentAccountPropShape } from 'interfaces/paymentAccount';
import { matchPropShape, historyPropShape, locationPropShape } from 'interfaces/navigation';
import { onGroupSettings } from 'utils/group';
import { StripeIdentityBanner } from 'frontend-toolkit';
import AcceptTerms from '../../screens/AcceptTerms/AcceptTermsContainer';
import { getPaymentAccounts, postCreateOnboardingLink } from 'services/paymentsV2';
import { VISA_BANNER_ACTION_LABEL, VISA_BANNER_TITLE, getVisaBannerText } from './constants';
import { shouldShowManageAccountButton, shouldShowTeamBanner } from 'utils/teamAccount';
import AccountSetupModal from '../AccountSetupModal';

const appRoutes = [
  { path: routes.root.path, redirect: routes.accounts.fullPath },
  { path: routes.accounts.path, component: Accounts, exact: true },
  { path: routes.acceptTerms.path, component: AcceptTerms },
  { path: routes.accountsEdit.path, component: AccountsEdit },
  { path: routes.payments.path, component: Payments },
  { path: routes.deposits.path, component: Deposits, exact: true },
  { path: routes.depositsShow.path, component: DepositsShow }
];

class TeamFinancials extends Component {
  constructor (props) {
    super(props);
    this.state = {
      showDepositAccountModal: false,
      canManageAccount: false,
      canCreateInvoice: true,
    };
  }

  componentDidMount () {
      const { match: { params: { teamId } }, isVisaMandateTeam, userIsTeamOwner } = this.props;
      if ( isVisaMandateTeam ) {
        // check for v2 payment account
        getPaymentAccounts({ scope_id: teamId, scope_type: 'team' }).then(response => {
          if (response.data) {
            this.shouldPromptStripeBanner(response.data);
            this.setState(
              {
                canManageAccount: shouldShowManageAccountButton(response.data, userIsTeamOwner),
                canCreateInvoice: ['approved', 'restricted_soon'].includes(response.data.status),
              }
            );
          } else {
            // the account is not yet created, redirect to empty account view
            window.location.assign(linkToTeamsAccounts({ teamId }));
          }
        });
      }  
      this.props.fetchPaymentAccountsByTeam(teamId).then(response => {
        this.shouldPromptStageTwo(response.payload);
        this.props.fetchFormFields(response.payload.id);
      });

  }

  componentDidUpdate () {
    this.shouldPromptStageTwo(this.props.paymentAccount);
  }

  shouldPromptStageTwo (account) {
    const { match: { params: { teamId } }, location: { pathname }, isVisaMandateTeam } = this.props;

    // this is the old banner and will be phased out after rollout
    if ( isVisaMandateTeam ) return;

    if (account && !account.isSetupComplete) {
      this.props.showFlashMessage({
        icon: 'notifications',
        color: 'highlight',
        title: 'Deposit Account Setup Pending',
        children: (
          <>
            Account setup needs to be completed before you can receive deposits.
            { !onGroupSettings(pathname, { teamId }) && (
                <Link
                  to={ linkToAccountsEdit({ accountId: account.id, teamId }) }
                  style={ { marginLeft: '10px' } }
                >
                  Complete Account
                </Link> 
            ) }
          </>
        ),
        handleClose: this.props.hideFlashMessage
      });
    }
    if (account && account.isSetupComplete && account.stripesAccountId) {
      this.props.showStripeBanner = true;
    }
  }

  launchOnboarding = (accountId) => {
    const params =
    {
      'refresh_url': window.location.href,
      'return_url': window.location.href,
      'type': 'account_onboarding'
    };
    postCreateOnboardingLink(accountId, params).then(({ data }) => {
      if (data?.url) window.location.assign(data.url);
    });
  };
  
  shouldPromptStripeBanner (account) {
    const { userIsTeamOwner } = this.props;

    if (shouldShowTeamBanner(account)) {
      this.props.showFlashMessage({
        sentiment: 'negative',
        title: VISA_BANNER_TITLE,
        caption: getVisaBannerText(account.status),
        action: shouldShowManageAccountButton(account, userIsTeamOwner ) && {
          onClick: () => this.launchOnboarding(account.id),
        },
        className: 'sui-my-3',
        onClose: this.props.hideFlashMessage
      });
    }
  }

  renderButtons = () => {
    const {
      location: { pathname },
      match: { params },
      history: { push },
      paymentAccountIsLoading,
      paymentAccount,
      isVisaMandateTeam,
    } = this.props;

    const isAccountsPath = !!matchPath(pathname, { path: routes.accounts.fullPath });

    const isReportsPath =
      !!matchPath(pathname, { path: routes.payments.fullPath, exact: true }) ||
      !!matchPath(pathname, { path: routes.deposits.fullPath, exact: true }) ||
      !!matchPath(pathname, { path: routes.outstandingPayments.fullPath, exact: true });

    const promptStageTwo = paymentAccount && !paymentAccount.isSetupComplete && !isAccountsPath;

    return [
      {
        label: 'Deposit Account',
        icon: promptStageTwo ? 'alert' : 'check',
        isDisabled: paymentAccountIsLoading,
        isActive: isAccountsPath,
        onClick: () => 
          isVisaMandateTeam ? window.location.assign(linkToTeamsAccounts(params)) : push(linkToRoot(params))
      },
      {
        label: 'Invoices',
        type: 'button',
        icon: 'credit-card',
        isDisabled: paymentAccountIsLoading,
        isActive: false,
        onClick: () => window.location.assign(`/${params.teamId}/team_payments/invoicing`)
      },
      {
        label: 'Financial Reporting',
        icon: 'statistics',
        isDisabled: paymentAccountIsLoading,
        isActive: isReportsPath,
        onClick: () => push(linkToPayments({ accountId: params.teamId, ...params })) 
      }
    ];
  }

  render () {
    const { 
      paymentAccountIsLoading, 
      match: { path, params }, 
      paymentAccount, 
      formFieldsAreLoading,
      showStripeBanner, 
      isVisaMandateTeam 
    } = this.props;

    if (paymentAccount && !paymentAccount.isSetupComplete) {
      appRoutes[0].path = path.replace(':teamId', `${params.teamId}`);
      appRoutes[0].redirect = linkToAccountsEdit({ ...params, accountId: paymentAccount.id });
    }

    return (
      <div>
        <header style={ { margin: '20px 20px 0' } }>
          <Grid>
            <Cell mods='u-sizeFill'>
              <h2>Team Financials</h2>
            </Cell>
            <ButtonGroup buttons={ this.renderButtons() } />
            <Button
              type='button'
              style={ { marginLeft: '10px' } }
              color='primary'
              icon='plus'
              onClick={ () => this.state.canCreateInvoice ? 
                window.location.assign(`/${params.teamId}/team_payments/invoicing/new`) :
                this.setState({ ...this.state, showDepositAccountModal: true })
              }
            >
              Send New Invoice
            </Button>
          </Grid>
        </header>
        <main>
          { /* eslint-disable-next-line no-constant-condition */ }
          { isVisaMandateTeam ? <ConnectedBanner /> : <FlashMessage /> }
          { (paymentAccountIsLoading || formFieldsAreLoading) &&
            <Loader type='spin' text='Loading...' /> }
          { showStripeBanner && <StripeIdentityBanner /> }

          { !paymentAccountIsLoading && !formFieldsAreLoading && paymentAccount &&
            <AppRoutes routes={ appRoutes } rootPath={ path } paymentAccount={ paymentAccount } /> }
        </main>
        <AccountSetupModal
          isOpen={ this.state.showDepositAccountModal }
          onClose={ () => this.setState({ ...this.state, showDepositAccountModal: false }) } 
          onAction={ this.state.canManageAccount && (async () => {
            await this.launchDepositAccountOnboarding();
            this.setState({ ...this.state, showDepositAccountModal: false });
          }) }
        />
      </div>
    );
  }
}

TeamFinancials.propTypes = {
  match: PropTypes.shape(matchPropShape).isRequired,
  history: PropTypes.shape(historyPropShape).isRequired,
  location: PropTypes.shape(locationPropShape).isRequired,
  paymentAccount: PropTypes.shape(paymentAccountPropShape),
  paymentAccountIsLoading: PropTypes.bool,
  fetchPaymentAccountsByTeam: PropTypes.func.isRequired,
  showFlashMessage: PropTypes.func.isRequired,
  hideFlashMessage: PropTypes.func.isRequired,
  formFieldsAreLoading: PropTypes.bool,
  fetchFormFields: PropTypes.func.isRequired,
  showStripeBanner: PropTypes.bool,
  userIsTeamOwner: PropTypes.bool,
  isVisaMandateTeam: PropTypes.bool,
};

TeamFinancials.defaultProps = {
  paymentAccount: null,
  paymentAccountIsLoading: true,
  formFieldsAreLoading: true,
  showStripeBanner: false,
  userIsTeamOwner: false,
  isVisaMandateTeam: false,
};

export default TeamFinancials;
