import { getCanUpdate } from 'services/payments';

// ----------------------------------------------------------------------------------------------------
// :: Action Types
// ----------------------------------------------------------------------------------------------------
export const CAN_UPDATE = 'CAN_UPDATE';
const FETCH_CAN_UPDATE = 'FETCH_CAN_UPDATE';
const ERROR_CAN_UPDATE = 'ERROR_CAN_UPDATE';
const SAVE_CAN_UPDATE = 'SAVE_CAN_UPDATE';

// ----------------------------------------------------------------------------------------------------
// :: Action Creators
// ----------------------------------------------------------------------------------------------------
export const fetchCanUpdate = (divisionId) => async dispatch => {
  dispatch({ type: FETCH_CAN_UPDATE });
  try {
    const response = await getCanUpdate(divisionId);
    return dispatch({ type: SAVE_CAN_UPDATE, payload: response.data });
  } catch (error) {
    dispatch({ type: ERROR_CAN_UPDATE, payload: error });
    return error;
  }
};
