import { connect } from 'react-redux';
import { acceptTerms } from 'state/acceptTerms/actions';
import { selectAcceptTerms } from 'state/acceptTerms/selectors';
import { hideFlashMessage, showFlashMessage } from 'state/app/actions';
import { selectPaymentAccount } from 'state/paymentAccounts/selectors';
import AcceptTerms from './AcceptTerms';

const mapDispatchToProps = {
  acceptTerms,
  showFlashMessage,
  hideFlashMessage,
};

const mapStateToProps = (state, ownProps) => ({
  requestError: state.acceptTerms.error,
  paymentAccount: selectPaymentAccount(state),
  acceptTermsData: selectAcceptTerms(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(AcceptTerms);
