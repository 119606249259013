import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Panel, Grid, Cell } from '@teamsnap/teamsnap-ui';
import AccountsUpdateForm from 'components/baseComponents/AccountsUpdateForm';
import { paymentAccountPropShape } from 'interfaces/paymentAccount';
import { matchPropShape, historyPropShape } from 'interfaces/navigation';
import { linkToAccounts } from 'utils/links';
import { getPaddedDateString } from 'utils/date';
import { SubmissionError } from 'redux-form';
import { formatErrorMessage } from 'utils/formatter';

class AccountsUpdate extends Component {
  verifyAge = dob => {
    const birthdate = new Date(dob).getTime();
    const currentYear = new Date().getFullYear();
    const eighteenYearsAgo = new Date().toString().replace(currentYear, currentYear - 18);
    return new Date(eighteenYearsAgo).getTime() >= birthdate;
  }

  submitPaymentAccount = async (values) => {
    if (values.dobYear) {
      const dob = getPaddedDateString(values.dobYear, values.dobMonth, values.dobDay);
      if (!this.verifyAge(dob)) {
        throw new SubmissionError({ dobYear: 'You must be over 18 to use TeamSnap Payments.' });
      }
    }

    const attributes = {
      'account_opener.first_name': values.firstName,
      'account_opener.last_name': values.lastName,
      'account_opener.address.line1': values.addressLine1,
      'account_opener.address.line2': values.addressLine2,
      'account_opener.address.city': values.addressCity,
      'account_opener.address.state': values.addressState,
      'account_opener.address.postal_code': values.addressPostalCode,
      'account_opener.ssn_last_4': values.ssnLast4,
      'account_opener.dob': getPaddedDateString(values.dobYear, values.dobMonth, values.dobDay),
      'company.name': values.companyName,
      'company.phone': values.companyPhone
    };

    return this.submitUpdate({ attributes });
  }

  submitUpdate = async (payload) => {
    const { paymentAccount, updatePaymentAccountWithFormFields } = this.props;

    const response = await updatePaymentAccountWithFormFields(paymentAccount.id, payload);
    if (response && response.payload && response.payload.error) {
      this.errorForm(response.payload.error);
    }
    if (response && response.error) {
      return this.errorForm(response.error);
    }

    this.props.showFlashMessage({
      autoClose: true,
      icon: 'check',
      color: 'positive',
      title: 'Success',
      message: 'The account settings have been updated',
      handleClose: this.props.hideFlashMessage
    });

    this.handleRedirectToAccounts(response);
  }

  errorForm = (error) => {
    this.props.showFlashMessage({
      autoClose: false,
      icon: 'dismiss',
      title: 'Submit Failed',
      message: formatErrorMessage(error.message),
      color: 'negative',
      handleClose: this.props.hideFlashMessage
    });

    throw new SubmissionError({ _error: 'Submit Failed' });
  }

  handleRedirectToAccounts = (response) => {
    const { history, match: { params } } = this.props;
    if (!response || response.payload.isSetupComplete) {
      return history.push(linkToAccounts(params));
    } else {
      return window.location.assign(linkToAccounts(params));
    }
  }

  render () {
    const { paymentAccount } = this.props;

    return (
      <div className={ !paymentAccount.isSetupComplete ? 'u-flexInline' : null }>
        <Panel style={ { marginTop: '20px', width: '48%', marginLeft: 'auto', marginRight: 'auto' } }>
          <Grid isWithGutter isAlignMiddle>
            <Cell mods='u-size1of1' style={ { padding: '0 30px' } }>
              <AccountsUpdateForm
                submitPaymentAccount={ this.submitPaymentAccount }
                onCancel={ () => this.handleRedirectToAccounts() }
                paymentAccount={ paymentAccount }
                isEditing
              />
            </Cell>
          </Grid>
        </Panel>
      </div>
    );
  }
}

AccountsUpdate.propTypes = {
  updatePaymentAccountWithFormFields: PropTypes.func.isRequired,
  showFlashMessage: PropTypes.func.isRequired,
  hideFlashMessage: PropTypes.func.isRequired,
  history: PropTypes.shape(historyPropShape).isRequired,
  match: PropTypes.shape(matchPropShape).isRequired,
  paymentAccount: PropTypes.shape(paymentAccountPropShape),
  requestError: PropTypes.shape({})
};

AccountsUpdate.defaultProps = {
  paymentAccount: null,
  requestError: null
};

export default AccountsUpdate;
