/**
 * Collection JSON
 *
 * A small parser to flatten the items array of objects into a more usuable structure
 *
 */
import _mapKeys from 'lodash/mapKeys';
import _parseInt from 'lodash/parseInt';
import { plissken } from './utils';
import { getDate } from './date-service';

// Convert keys to camelCase
const objectKeysToCamel = (key, value) => ({ [plissken(key)]: value });

// Make sure all returned values from the api are of the correct 'type'
const convertValueType = (value, type) => {
  if (value !== undefined && value !== null) {
    if (type === 'String') {
      return String(value);
    } else if (type === 'Boolean') {
      return String(value) === 'true';
    } else if (type === 'Integer') {
      return _parseInt(value);
    } else if (type === 'DateTime') {
      return getDate(value);
    }
  }

  return value;
};

// Assign array colletion to mapped object
const assignCollection = (collection, key, value) =>
  Object.assign(...collection.map((data) => objectKeysToCamel(data[key], convertValueType(data[value], data.type))));

// Updated item collection from collectionJSON
const collectionItem = (item) => ({
  href: item.href,
  rel: item.rel,
  links: item.links && item.links.length > 0 ? assignCollection(item.links, 'rel', 'href') : [],
  ...assignCollection(item.data, 'name', 'value'),
});

// Convert CollectionJSON to JSON
const convertFromCollection = ({ collection = {} }) => ({
  ...collection,
  links: collection.links && collection.links.length > 0 ? assignCollection(collection.links, 'rel', 'href') : [],
  items: collection.items && collection.items.length > 0 ? collection.items.map((item) => collectionItem(item)) : [],
});

// Convert JSON to collectionJSON structur
const convertToCollection = (data, command) => {
  let payload = {};

  // CMD and Items, post separate body structures
  if (command) {
    payload = _mapKeys(data, (value, key) => plissken(key, true));
  } else {
    payload = Object.keys(data).map((key) => ({ name: plissken(key, true), value: data[key] }));
  }

  return command ? payload : { template: { data: payload } };
};

export { objectKeysToCamel, convertFromCollection, convertToCollection };
