import React from 'react';
import PropTypes from 'prop-types';
import { Select as UISelect } from '@teamsnap/teamsnap-ui';

const Select = ({ field, options }) => (
  <UISelect name={ field.name } inputProps={ field } options={ options } />
);

Select.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default Select;
