import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Feedback from 'components/teamsnapUIExtensions/Feedback';
import { selectFlashMessage } from 'state/app/selectors';

class FlashMessage extends Component {
  render () {
    const { showFlashMessage, flashMessageProps } = this.props;

    if (!showFlashMessage) { return null; }

    return <Feedback { ...flashMessageProps } />;
  }
}

FlashMessage.propTypes = {
  flashMessageProps: PropTypes.shape({}).isRequired,
  showFlashMessage: PropTypes.bool.isRequired
};

const mapDispatchToProps = {};

const mapStateToProps = state => ({
  ...selectFlashMessage(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(FlashMessage);
