import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const defaultDateFormat = 'MM/DD/YYYY';

export const formatDate = (dateString, stringFormat = defaultDateFormat) =>
  dayjs.utc(dateString).format(stringFormat);

export const formatYearMonthDay = (year, month, day, stringFormat = defaultDateFormat) =>
  dayjs(getPaddedDateString(year, month, day)).format(stringFormat);

export const getStartOfDay = date =>
  dayjs.utc(date).startOf('day').format();

export const getEndOfDay = date =>
  dayjs.utc(date).endOf('day').format();

const padDateValue = value => value.padStart(2, '0');

export const getPaddedDateString = (year, month, day) =>
  `${year}-${padDateValue(month)}-${padDateValue(day)}`;

const isDateValid = (date) =>
  dayjs(date).isValid();

export const parseDate = date => {
  if (isDateValid(date)) {
    return new Date(date);
  } else {
    return null;
  }
};

export const getThirtyDaysAgo = () =>
  dayjs().subtract(30, 'day');
