import PropTypes from 'prop-types';

export const chargePropShape = {
  amount: PropTypes.number,
  amountRefunded: PropTypes.number,
  amountRefundedWithCurrency: PropTypes.string,
  amountWithCurrency: PropTypes.string,
  applicationId: PropTypes.string,
  currency: PropTypes.string,
  id: PropTypes.string,
  metadata: PropTypes.shape({}),
  paymentMethodDetails: PropTypes.shape({
    last4: PropTypes.string,
    country: PropTypes.string,
    id: PropTypes.string
  }),
  reportingDescription: PropTypes.string,
  status: PropTypes.string
};
