export const selectPaymentAccount = (state) => state.paymentAccounts.data;

export const selectPaymentAccountId = (state) => state.paymentAccounts.data.id;

export const selectPaymentAccountIsLoading = (state) => state.paymentAccounts.isLoading;

export const selectPaymentAccountCurrentlyDueRequirements = (state) =>
  state.paymentAccounts.data?.account?.requirements?.currentlyDue;

export const selectPaymentAccountCurrentDeadline = (state) =>
  state.paymentAccounts.data?.account?.requirements?.currentDeadline;

export const selectPaymentAccountDisabledReason = (state) =>
  state.paymentAccounts.data?.account?.requirements?.disabledReason;
