/**
 * Helper Functions
 *  Various helper functions that are shared accross the app. Consider breaking functions out into
 *  "groups", if the file starts to get large.
 *
 */

import { snakeCase, camelCase, startCase, reduce, range } from './underdash';

// Check if a value is an Object
export const isObject = value => Object.prototype.toString.call(value) === '[object Object]';

// Check if value is an Array
export const isArray = value => Array.isArray(value);

// Convert snake_case <- -> camelCase with 'Snake' Plissken
export const plissken = (string, isSnake = false) => (isSnake ? snakeCase(string) : camelCase(string));

// Converts object keys from snake_case to camelCase and back.
export const objectKeysToCase = (value, isSnakeCase = false) => {
  if (isArray(value)) {
    return value.map(v => objectKeysToCase(v, isSnakeCase));
  } else if (!isObject(value)) {
    return value;
  }

  return reduce(value, (r, v, k) => ({
    ...r,
    [plissken(k, isSnakeCase)]: objectKeysToCase(v, isSnakeCase)
  }), {});
};

// Convert an array of objects to a single object
export const arrayToObject = (value, key = 'id') => {
  if (isObject(value)) {
    return { [value[key]]: value };
  }

  return (
    value.reduce((object, item) => {
      object[item[key]] = item;
      return object;
    }, {})
  );
};

export const repeatCharacter = (counter, character) => range(0, counter, 1).map(_ => character).join('');

export const camelCaseToWords = (string) => startCase(string.replace(/_/g, ' '));

export const truncate = (string, length = 10) => {
  if (string && string.length > length) {
    return string.substring(0, length) + '...';
  } else {
    return string;
  }
};

// TEMP: Quick conversion from stripe cents to dollars
export const convertDollars = (cents = 0) => {
  const dollars = (cents / 100);
  return `$${dollars.toLocaleString('en', { currency: 'USD', minimumFractionDigits: 2 })}`;
};

export const stringifyArray = (array, joinBy = ' ') => array.filter(Boolean).join(joinBy);

export const getClassName = (className, ...classModifiers) => {
  const classes = [
    className,
    ...classModifiers
  ];

  return stringifyArray(classes);
};
