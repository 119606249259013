import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DynamicAccountsForm from './DynamicAccountsForm';
import initialValueBuilder from './initialValuesBuilder';
import { combineFormFields } from 'utils/formFields';
import { paymentAccountPropShape } from 'interfaces/paymentAccount';

class AccountsFormBuilder extends Component {
  render () {
    const {
      paymentAccount,
      onCancel,
      isEditing,
      submitPaymentAccount,
      formFieldsBySection
    } = this.props;

    const handleSubmitForm = (_values, _other) => {
      submitPaymentAccount(_values);
    };

    const handleCancelForm = () => {
      onCancel();
    };

    return (
      <DynamicAccountsForm
        isEditing={ isEditing }
        initialValues={ initialValueBuilder(paymentAccount, isEditing, combineFormFields(formFieldsBySection)) }
        onSubmit={ (values) => handleSubmitForm(values) }
        handleCancel={ handleCancelForm }
        paymentAccount={ paymentAccount }
        formFieldsBySection={ formFieldsBySection }
      />
    );
  }
}

AccountsFormBuilder.propTypes = {
  paymentAccount: PropTypes.shape(paymentAccountPropShape).isRequired,
  onCancel: PropTypes.func,
  isEditing: PropTypes.bool.isRequired,
  submitPaymentAccount: PropTypes.func.isRequired,
  formFieldsBySection: PropTypes.array
};

AccountsFormBuilder.defaultProps = {
  onCancel: null,
  formFieldsBySection: []
};

export default AccountsFormBuilder;
