import { Cell, Checkbox, Grid, Panel } from '@teamsnap/teamsnap-ui';
import { StyledButton, StyledPanelHeader } from 'components/baseComponents/AccountsFormBuilder/styled';
import FieldError from 'components/teamsnapUIExtensions/FieldError';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class AcceptTermsForm extends Component {
  constructor(props) {
    super(props);
    this.state = { acceptError: false, accepted: false, submitting: false };
  }

  render() {
    const { acceptError, submitting } = this.state;
    const { onSubmit } = this.props;

    const checkBoxToggle = () => {
      if(this.state.accepted) {
        this.setState({ accepted: false, acceptError: true });
      } else {
        this.setState({ accepted: true, acceptError: false });
      }
    };

    const buttonClicked = async () => {
      if(this.state.accepted) {
        try {
          this.setState({ submitting: true });
          await onSubmit();
        } finally {
          this.setState({ submitting: false });
        }
      } else {
        this.setState({ acceptError: true });
      }
    };

    return (
        <Panel style={ { marginTop: '20px', width: '48%', marginLeft: 'auto', marginRight: 'auto' } }>
          <Grid isWithGutter isAlignMiddle>
            <Cell mods="u-size1of1" style={ { padding: '10px 30px' } }>
              <Grid isWithGutter>
                <Cell mods="u-size1of1">
                  <StyledPanelHeader title="Accept Stripe's Terms of Serive"/>
                  <br />
                </Cell>
                <Cell mods="u-size3of4">
                  <Checkbox name="accept" id="accept" label={
                      <span>
                      I accept Stripe's <a target="_blank" rel="noreferrer" href="https://stripe.com/connect-account/legal">Terms of Service</a>
                      </span> 
                    }
                    inputProps={ { onClick: checkBoxToggle } }
                  />
                  { acceptError && <FieldError>You must accept the terms of service</FieldError> }
                </Cell>
                <Cell mods="u-size2of4">
                  <StyledButton size='large' isDisabled={ submitting } onClick={ buttonClicked } icon="check" color="primary">
                    Save
                  </StyledButton>
                </Cell>
              </Grid>
            </Cell>
          </Grid>
        </Panel>
    );
  }
}

AcceptTermsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default AcceptTermsForm;
