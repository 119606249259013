import { Cell, Grid } from '@teamsnap/teamsnap-ui';
import FieldInput from 'components/teamsnapUIExtensions/FieldInput';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Form, reduxForm } from 'redux-form';
import { StyledPanelHeader, StyledSubmitButton } from '../AccountsFormBuilder/styled';
import { validate } from '../AccountsFormBuilder/validationBuilder';

class NewAccountsForm extends Component {
  validator = (value, allValues, _props, name) => validate(value, null, name, true, allValues);

  render() {
    const { handleSubmit, initialValues, submitting, division, creatingOnboardingLink } = this.props;

    return (
      <Form onSubmit={ handleSubmit }>
        <Grid isWithGutter>
          <Cell mods="u-size1of1">
            <StyledPanelHeader title="Merchant Account Information" />
          </Cell>

          { division && division.businessType === 'individual' ? (
            <>
              <Cell mods="u-size4of4">
                <FieldInput
                  name="firstName"
                  validate={ this.validator }
                  initialValues={ initialValues }
                  label="First Name"
                  placeholder="Enter First Name"
                />
              </Cell>
              <Cell mods="u-size4of4">
                <FieldInput
                  name="lastName"
                  validate={ this.validator }
                  initialValues={ initialValues }
                  label="Last Name"
                  placeholder="Enter Last Name"
                />
              </Cell>
            </>
          ) : (
            <Cell mods="u-size4of4">
              <FieldInput
                name="legalEntityName"
                validate={ this.validator }
                initialValues={ initialValues }
                label="Legal Entity Name"
                placeholder="Organization Name"
              />
            </Cell>
          ) }

          <Cell mods="u-size4of4">
            <FieldInput
              name="nickname"
              validate={ this.validator }
              initialValues={ initialValues }
              label="Merchant Account Nickname"
              placeholder="Account Nickname"
            />
          </Cell>

          <Cell mods="u-size4of4">
            <FieldInput
              name="payoutDescriptor"
              validate={ this.validator }
              initialValues={ initialValues }
              label="Payout Descriptor"
              placeholder="Text that appears on a bank account statement for payouts"
            />
          </Cell>

          { creatingOnboardingLink }

          <Cell mods="u-size1of1">
            <StyledSubmitButton isDisabled={ submitting || creatingOnboardingLink } icon="check" color="primary">
              Create Merchant Account
            </StyledSubmitButton>
          </Cell>
        </Grid>
      </Form>
    );
  }
}

NewAccountsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
};

export default reduxForm({ form: 'accountNew' })(NewAccountsForm);
