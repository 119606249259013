import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { PaginatedTable } from '@teamsnap/teamsnap-ui';
import DatePicker from 'components/teamsnapUIExtensions/DatePicker';
import LoaderModal from 'components/teamsnapUIExtensions/LoaderModal';
import { Loader } from '@teamsnap/teamsnap-ui';
import FileExport from '../../baseComponents/FileExport';
import filterDates from '../../../utils/filter-dates';

const ReportsTableWithPagination = ({ columns, rows, formatRow, buildCSVRow, isLoaded }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [customFilter, setCustomFilter] = useState({});
  const [filteredRows, setfilteredRows] = useState(rows);

  const loadData = useCallback( async ({ page, itemsPerPage, filter }) => {
    const startIndex = itemsPerPage * page - itemsPerPage;
    const filteredRows = filterDates(rows || [], filter?.dateObj);
    setfilteredRows(filteredRows);
    return new Promise((resolve) => resolve(filteredRows)).then((items) => {
      const endIndex = Math.min(items.length, startIndex + itemsPerPage);
      return items.slice(startIndex, endIndex);
    });
  }, [rows]);

  const isLoading = loading => setIsDownloading(loading);
  const handleFilterData = (dateObj) =>  setCustomFilter({ dateObj });

  return (
    <div>
      { isLoaded ? (
        <PaginatedTable
          columns={ columns }
          mapDataToRow={ formatRow }
          loadData={ loadData }
          defaultItemsPerPage={ 50 }
          totalItems={ filteredRows.length }
          customFilter={ customFilter }
          customSearchFilter={ (
            <div className='u-flex'>
              <DatePicker
                isRange
                placeholderTextStart='Select Start Date'
                placeholderTextEnd='Select End Date'
                onButtonClick={ handleFilterData }
              />

              <FileExport
                name='outstanding-payments'
                label='Export to CSV'
                data={ filteredRows.map((row) => buildCSVRow(row)) }
                type='csv'
                isLoading={ isLoading }
              />
            </div>
          ) }
          paginationPlacement={ PaginatedTable.PaginationPlacement.Bottom }
        />
      ) : (
        <Loader type='spin' text='loading...' />
      ) }

      <LoaderModal
        show={ isDownloading }
        handleClose={ () => setIsDownloading(false) }
      />
    </div>
  );
};

ReportsTableWithPagination.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  formatRow: PropTypes.func,
  buildCSVRow: PropTypes.func.isRequired,
  isLoaded: PropTypes.bool,
};

ReportsTableWithPagination.defaultProps = {
  formatRow: null,
  isLoaded: false
};

export default ReportsTableWithPagination;
