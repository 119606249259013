import { api } from './api';

const endpoints = {
  paymentAccounts: '/payment_accounts/',
  paymentAccount: '/payment_accounts/:accountId',
  charges: '/payment_accounts/:accountId/charges',
  payouts: '/payment_accounts/:accountId/payouts',
  payout: '/payment_accounts/:accountId/payouts/:payoutId',
  payoutTransactions: '/payment_accounts/:accountId/payouts/:payoutId/transactions',
  formFields: '/payment_accounts/:accountId/form_fields',
  canUpdate: '/payment_accounts/can_update',
  updateIdNumber: '/payment_accounts/:accountId/update_id_number',
  acceptTerms: '/payment_accounts/:accountId/accept_terms',
  division: '/divisions/:divisionId',
};

const getEndpoint = (name, params) =>
  endpoints[name]
    .replace(':accountId', params.accountId)
    .replace(':payoutId', params.payoutId)
    .replace(':divisionId', params.divisionId);

export const getPayout = ({ accountId, payoutId, ...params }) =>
  api.get(getEndpoint('payout', { accountId, payoutId }), params);

export const getPayoutTransactions = ({ accountId, payoutId, ...params }) =>
  api.get(getEndpoint('payoutTransactions', { accountId, payoutId }), params);

export const getPayouts = ({ accountId, ...params }) => api.get(getEndpoint('payouts', { accountId }), params);

export const getCharges = ({ accountId, ...params }) => api.get(getEndpoint('charges', { accountId }), params);

export const getCanUpdate = (divisionId) => api.get(endpoints.canUpdate, { divisionId });

export const getPaymentAccountsBy = (params) => api.get(endpoints.paymentAccounts, params);

export const getPaymentAccount = (accountId) => api.get(getEndpoint('paymentAccount', { accountId }));

export const getOutstandingPayments = (divisionId) => api.get(`outstanding_payments/${divisionId}`);

export const patchPaymentAccount = (accountId, data) => api.patch(getEndpoint('paymentAccount', { accountId }), data);

export const postIdNumber = (accountId, idNumber) =>
  api.post(getEndpoint('updateIdNumber', { accountId }), { id_number: idNumber });

export const getFormFields = (accountId) => api.get(getEndpoint('formFields', { accountId }));

export const getDivision = (divisionId) => api.get(getEndpoint('division', { divisionId }));

export const patchPaymentAccountWithFormFields = (accountId, data) =>
  api.patchFormFields(getEndpoint('paymentAccount', { accountId }), data);

export const postAcceptTerms = (accountId) => api.post(getEndpoint('acceptTerms', { accountId }), {});
