import React from 'react';
import PropTypes from 'prop-types';
import { Cell } from '@teamsnap/teamsnap-ui';
import FieldBuilder from './FieldBuilder';
import { StyledPanelHeader } from './styled';
import { paymentAccountPropShape } from 'interfaces/paymentAccount';

const FormGroup = (props) => {
  const {
    fields,
    title,
    subtitle,
    initialValues,
    isEditing,
    paymentAccount
  } = props;

  return (
    <>
      <Cell mods='u-size1of1'>
        <StyledPanelHeader title={ title } subtitle={ subtitle } />
      </Cell>
      { fields && fields.map((field, i) => (
        <FieldBuilder
          key={ i }
          field={ field }
          paymentAccount={ paymentAccount }
          initialValues={ initialValues }
          label={ field.label }
          isEditing={ isEditing }
        />
      )) }
    </>
  );
};

FormGroup.propTypes = {
  fields: PropTypes.array,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isEditing: PropTypes.bool.isRequired,
  paymentAccount: PropTypes.shape(paymentAccountPropShape).isRequired,
  initialValues: PropTypes.shape({}).isRequired
};

FormGroup.defaultProps = {
  fields: [],
  title: '',
  subtitle: ''
};

export default FormGroup;
