import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import app from 'state/app/reducer';
import { createReducer, createReducerWithPagination } from 'state/baseReducer';
import { CAN_UPDATE } from 'state/canUpdate/actions';
import { CHARGES } from 'state/charges/actions';
import { EXPORT_DATA } from 'state/exportData/actions';
import { FORM_FIELDS } from 'state/formFields/actions';
import { OUTSTANDING_PAYMENTS } from 'state/outstandingPayments/actions';
import { PAYMENT_ACCOUNT } from 'state/paymentAccounts/actions';
import { PAYOUTS } from 'state/payouts/actions';
import { PAYOUT_TRANSACTIONS } from 'state/payoutTransactions/actions';
import { USER } from 'state/users/actions';
import { ACCEPT_TERMS } from './acceptTerms/actions';
import { ID_NUMBER } from './idNumber/actions';
import { DIVISION } from './division/actions';
import { ONBOARDING_LINK } from './onboardingLink/actions';

export default combineReducers({
  app,
  paymentAccounts: createReducer(PAYMENT_ACCOUNT),
  canUpdate: createReducer(CAN_UPDATE),
  idNumber: createReducer(ID_NUMBER),
  acceptTerms: createReducer(ACCEPT_TERMS),
  charges: createReducerWithPagination(CHARGES),
  payouts: createReducerWithPagination(PAYOUTS),
  payoutTransactions: createReducerWithPagination(PAYOUT_TRANSACTIONS),
  users: createReducer(USER),
  formFields: createReducer(FORM_FIELDS),
  exportData: createReducer(EXPORT_DATA),
  form: formReducer,
  outstandingPayments: createReducer(OUTSTANDING_PAYMENTS),
  division: createReducer(DIVISION),
  onboardingLink: createReducer(ONBOARDING_LINK),
});
