import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';

class ErrorBoundary extends Component {
  state = {
    hasError: false
  }

  static getDerivedStateFromError (error) {
    // Update state so next render will show the fallback UI
    console.warn(error);
    return { hasError: true };
  }

  componentDidCatch (error, errorInfo) {
    // Send error to reporting service.
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });
  }

  render () {
    if (this.state.hasError) {
      return <p>Something went wrong.</p>;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired
};

export default ErrorBoundary;
