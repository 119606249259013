import React, { Component } from 'react';

class Root extends Component {
  render () {
    return (
      <p>
        TeamSnap League Financials - Coming Soon
      </p>
    );
  }
}

export default Root;
