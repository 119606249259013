/**
 * @name Feedback
 *
 * @description
 *  An alert component that will render styles for warnings, errors and other feedback to the users.
 *    See the Teamsnap patterns library for more information.  https://teamsnap-ui-patterns.netlify.com/patterns/components/feedback.html
 *
 * @example
 *  <Feedback icon='' title='' />
 *
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@teamsnap/teamsnap-ui';
import { getClassName } from 'utils/helpers';

// TODO: This needs to be moved to the @teamsnap/teamsnap-ui library
//  * https://teamsnap-ui-patterns.netlify.com/patterns/components/feedback.html

class Feedback extends Component {
  componentDidMount () {
    this.setAutoCloseTimeout(this.props);
  }

  componentWillUnmount () {
    this.clearAutoCloseTimeout();
  }

  componentDidUpdate () {
    // Clear AutoClose Timers if they exist
    this.clearAutoCloseTimeout();

    // Setup New AutoClose Timer if needed
    this.setAutoCloseTimeout(this.props);
  }

  setAutoCloseTimeout = (props) => {
    const { autoClose, autoCloseDuration, handleClose } = props;

    if (autoClose && handleClose) {
      this.autoCloseTimer = setTimeout(() => {
        handleClose();
      }, autoCloseDuration);
    }
  }

  clearAutoCloseTimeout = () => {
    if (this.autoCloseTimer) {
      clearTimeout(this.autoCloseTimer);
    }
  }

  renderDismiss = (handleClose) => (
    <i className='Feedback-dismiss' onClick={ handleClose }>
      <Icon name='dismiss' />
    </i>
  )

  renderIcon = (icon) => (
    <div className='Feedback-icon'>
      <Icon name={ icon } />
    </div>
  )

  render () {
    const { icon, title, message, children, handleClose, className, color, style, mods, otherProps } = this.props;

    const feedbackClassName = getClassName(
      className,
      handleClose && 'Feedback--dismissible',
      color && `Feedback--${color}`,
      mods
    );

    const feedbackStyle = {
      ...(!icon && { paddingLeft: '8px' }),
      ...style
    };

    return (
      <div className={ feedbackClassName } role='alert' style={ feedbackStyle } { ...otherProps }>
        { icon && this.renderIcon(icon) }
        { title && <span className='Feedback-title'>{ title }</span> }
        { children || message }
        { handleClose && this.renderDismiss(handleClose) }
      </div>
    );
  }
}

Feedback.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  children: PropTypes.node,
  handleClose: PropTypes.func,
  className: PropTypes.string,
  color: PropTypes.oneOf([null, 'positive', 'negative', 'highlight']),
  style: PropTypes.shape({}),
  mods: PropTypes.string,
  otherProps: PropTypes.shape({}),
  autoClose: PropTypes.bool,
  autoCloseDuration: PropTypes.number
};

Feedback.defaultProps = {
  icon: null,
  title: null,
  message: null,
  children: null,
  handleClose: null,
  className: 'Feedback',
  color: null,
  style: {},
  mods: null,
  otherProps: {},
  autoClose: false,
  autoCloseDuration: 5000
};

export default Feedback;
