import React, { Component } from 'react';
import PropTypes from 'prop-types';

class PanelHeader extends Component {
  render () {
    const { className, style, otherProps, title, subtitle, children } = this.props;

    return (
      <header className={ className } style={ style } { ...otherProps }>
        <h3 className='Panel-title'>{ title }
          { subtitle && <span style={ { marginLeft: '5px' } } className='u-colorGrey'>{ subtitle }</span> }
        </h3>
        { children }
      </header>
    );
  }
}

PanelHeader.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.shape({}),
  otherProps: PropTypes.shape({})
};

PanelHeader.defaultProps = {
  children: null,
  title: '',
  subtitle: '',
  className: 'Panel-header',
  style: {},
  otherProps: {}
};

export default PanelHeader;
