import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Loader, Button, Panel, PanelHeader, PanelRow, PanelCell } from '@teamsnap/teamsnap-ui';
import ReportsTable from 'components/baseComponents/ReportsTable';
import ReportsAnchorTag from 'components/baseComponents/ReportsAnchorTag';
import { linkToDeposits } from 'utils/links';
import { formatDate } from 'utils/date';
import { truncate, repeatCharacter, camelCaseToWords } from 'utils/helpers';
import styled from '@emotion/styled';
import { getGroupParams } from 'utils/group';
import { paymentAccountPropShape } from 'interfaces/paymentAccount';
import { payoutPropShape } from 'interfaces/payout';
import { historyPropShape, matchPropShape } from 'interfaces/navigation';

const StyledList = styled.ul`
  list-style: none;

  li {
    color: #383838;
    font-size: 14px;
  }

  span {
    display: inline-block;
    color: #7a7a7a;
    width: 140px;
  }
`;

class DepositsShow extends Component {
  componentDidMount () {
    const {
      payout,
      payoutsLoading,
      match: {
        params: { depositId }
      }
    } = this.props;

    if (!payout.id && !payoutsLoading) {
      this.props.fetchPayout(depositId);
    }
  }

  fetch = (params = {}, direction = null) => {
    const {
      match: {
        params: { depositId }
      }
    } = this.props;

    let fetchTransactions = this.props.fetchPayoutTransactions;

    if (direction === 'next') {
      fetchTransactions = this.props.fetchNextPayoutTransactions;
    } else if (direction === 'prev') {
      fetchTransactions = this.props.fetchPrevPayoutTransactions;
    }

    fetchTransactions(depositId);
  }

  handleRedirectToDeposits = () => {
    const { paymentAccount, history, match: { params }, clearPayouts } = this.props;
    clearPayouts();
    history.push(linkToDeposits({
      ...getGroupParams(params),
      accountId: paymentAccount.id
    }));
  }

  render () {
    const {
      payout,
      payoutTransactions,
      paginate,
      payoutTransactionsLoading,
      match: {
        params
      }
    } = this.props;

    // Return Early, if payout is still loading
    if (!payout.id) {
      return <Loader type='spin' text='Loading...' />;
    }

    const columns = [
      { name: 'createdAt', label: 'Date', isSortable: false },
      {
        name: 'details',
        label: 'Title',
        mods: 'u-size3of24',
        isSortable: false,
        render: (column, row) => (
          <>
            { row[column.name].title }
            <span style={ { display: 'block', fontSize: '12px', color: '#acacac' } }>
              { truncate(row[column.name].description, 132) }
            </span>
          </>
        ),
        renderCSV: item => (!item.title ? '' : `${item.title}`)
      },
      { name: 'amountWithCurrency', label: 'Gross', isSortable: false },
      { name: 'feeWithCurrency', label: 'Fee', isSortable: false },
      { name: 'netWithCurrency', label: 'Net', isSortable: false },
      { name: 'type', label: 'Type', isSortable: false },
      {
        name: 'paymentDetails',
        label: 'Payment Details',
        mods: 'u-size2of24',
        isSortable: false,
        render: (column, row) =>
          row[column.name] && (
            <>
              { row[column.name].name }
              <span style={ { paddingLeft: '8px' } }>
                { repeatCharacter(4, '\u2022') } { row[column.name].last4 }
              </span>
              <span style={ { display: 'block', fontSize: '12px', color: '#acacac' } }>
                { row[column.name].payerName }
              </span>
            </>
          ),
        renderCSV: item => `${item.name}: ${item.last4}`
      },
      {
        name: 'teamsnapWebContextUrl',
        label: null,
        mods: 'u-sizeFill',
        render: (column, row) => row[column.name] && <ReportsAnchorTag href={ row[column.name] } />,
        renderCSV: null
      }
    ];

    const formatRows = payoutTransactions => payoutTransactions
      .filter(transaction => transaction.type !== 'payout')
      .map(transaction => ({
        ...transaction,
        type: camelCaseToWords(transaction.type),
        details: { title: transaction.reportingTitle, description: transaction.reportingDescription },
        participant: transaction.reportingDescription,
        payerName: transaction.paymentMethodDetails && transaction.paymentMethodDetails.payerName,
        createdAt: formatDate(transaction.createdAt),
        ...(transaction.paymentMethodDetails.bankName && {
          paymentDetails: {
            name: transaction.paymentMethodDetails.bankName,
            last4: transaction.paymentMethodDetails.last4,
            payerName: transaction.paymentMethodDetails.payerName
          }
        }),
        ...(transaction.paymentMethodDetails.brand && {
          paymentDetails: {
            name: transaction.paymentMethodDetails.brand,
            last4: transaction.paymentMethodDetails.last4,
            payerName: transaction.paymentMethodDetails.payerName
          }
        }),
        paymentLast4: transaction.paymentMethodDetails ? transaction.paymentMethodDetails.last4 : null
      }));
    const rows = formatRows(payoutTransactions);

    return (
      <div style={ { marginTop: '30px' } }>
        <Panel style={ { marginBottom: '40px' } }>
          <PanelHeader title='Overview'>
            <Button
              icon='left'
              onClick={ this.handleRedirectToDeposits }
            >
              Back to Reports
            </Button>
          </PanelHeader>

          <PanelRow isWithCells>
            <PanelCell mods='u-size1of2'>
              <StyledList>
                <li>
                  <span>Date Paid:</span>
                  { formatDate(payout.arrivalDate) }
                </li>
                <li>
                  <span>Amount:</span>
                  { payout.amountWithCurrency }
                </li>
                <li>
                  <span>ID:</span>
                  { payout.id }
                </li>
              </StyledList>
            </PanelCell>
            <PanelCell mods='u-size1of2'>
              <StyledList>
                <li>
                  <span>Deposit Type:</span>
                  { !payout.destination.deleted && camelCaseToWords(payout.destination.object) }
                </li>
                <li>
                  <span>Deposit Name:</span>
                  { payout.destination.bankName }
                </li>
                { payout.destination.object === 'bank_account' && (
                  <li>
                    <span>Routing Number:</span>
                    { payout.destination.routingNumber }
                  </li>
                ) }
                <li>
                  <span>Account Number:</span>
                  { payout.destination.last4 && `${repeatCharacter(7, '\u2022')} ${payout.destination.last4}` }
                </li>
              </StyledList>
            </PanelCell>
          </PanelRow>
        </Panel>

        <ReportsTable
          heading='Transactions'
          columns={ columns }
          rows={ rows }
          paginate={ paginate }
          fetch={ this.fetch }
          loading={ payoutTransactionsLoading }
          dataType='payoutTransactions'
          formatRows={ formatRows }
          payoutId={ params.depositId }
        />
      </div>
    );
  }
}

DepositsShow.propTypes = {
  match: PropTypes.shape(matchPropShape).isRequired,
  history: PropTypes.shape(historyPropShape).isRequired,
  payoutTransactions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  paginate: PropTypes.shape({}).isRequired,
  paymentAccount: PropTypes.shape(paymentAccountPropShape).isRequired,
  payout: PropTypes.shape(payoutPropShape).isRequired,
  fetchPayout: PropTypes.func.isRequired,
  fetchPayoutTransactions: PropTypes.func.isRequired,
  fetchNextPayoutTransactions: PropTypes.func.isRequired,
  fetchPrevPayoutTransactions: PropTypes.func.isRequired,
  payoutTransactionsLoading: PropTypes.bool,
  clearPayouts: PropTypes.func.isRequired,
  payoutsLoading: PropTypes.bool
};

DepositsShow.defaultProps = {
  payoutTransactionsLoading: false,
  payoutsLoading: false
};

export default DepositsShow;
