import { getFormFields } from 'services/payments';
import { formatFormFieldData } from 'utils/formFields';

// ----------------------------------------------------------------------------------------------------
// :: Action Types
// ----------------------------------------------------------------------------------------------------
export const FORM_FIELDS = 'FORM_FIELDS';
const FETCH_FORM_FIELDS = 'FETCH_FORM_FIELDS';
const SAVE_FORM_FIELDS = 'SAVE_FORM_FIELDS';
const ERROR_FORM_FIELDS = 'ERROR_FORM_FIELDS';

// ----------------------------------------------------------------------------------------------------
// :: Action Creators
// ----------------------------------------------------------------------------------------------------
export const fetchFormFields = groupId => async dispatch => {
  dispatch({ type: FETCH_FORM_FIELDS });
  try {
    const response = await getFormFields(groupId);
    return dispatch({
      type: SAVE_FORM_FIELDS,
      payload: formatFormFieldData(response.data.formFields)
    });
  } catch (error) {
    dispatch({ type: ERROR_FORM_FIELDS, payload: error.error });
    return error;
  }
};
