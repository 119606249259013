import { Cell, FieldLabel, Grid } from '@teamsnap/teamsnap-ui';
import FieldInput from 'components/teamsnapUIExtensions/FieldInput';
import { paymentAccountPropShape } from 'interfaces/paymentAccount';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Form, reduxForm } from 'redux-form';
import { StyledPanelHeader, StyledSubmitButton } from '../AccountsFormBuilder/styled';
import { validate } from '../AccountsFormBuilder/validationBuilder';

class StaticAccountsForm extends Component {
  validator = (value, allValues, _props, name) => {
    const { paymentAccount } = this.props;
    const isRequired = name !== 'addressLine2';
    return validate(value, paymentAccount, name, isRequired, allValues);
  }

  render () {
    const {
      handleSubmit,
      initialValues,
      paymentAccount,
      submitting
    } = this.props;

    return (
      <Form onSubmit={ handleSubmit }>
        <Grid isWithGutter>
          <Cell mods='u-size1of1'>
            <StyledPanelHeader title='Account Opener' subtitle='(we must validate your identity)' />
          </Cell>

          <Cell mods='u-size3of4'>
            <FieldInput
              name='firstName'
              validate={ this.validator }
              initialValues={ initialValues }
              label='First Name'
              placeholder='Enter First Name'
            />
          </Cell>

          <Cell mods='u-size3of4'>
            <FieldInput
              name='lastName'
              validate={ this.validator }
              initialValues={ initialValues }
              label='Last Name'
              placeholder='Enter Last Name'
            />
          </Cell>

          <Cell mods='u-size1of1'>
            <FieldInput
              name='addressLine1'
              validate={ this.validator }
              initialValues={ initialValues }
              label='Address Line 1'
              placeholder='Street and Number'
              toolTipText='For your protection, we validate the identity of the organization of the collected funds. The physical address enables us to do that. PO Boxes do not suffice for validation.'
            />
          </Cell>

          <Cell mods='u-size1of1'>
            <FieldInput
              name='addressLine2'
              validate={ this.validator }
              initialValues={ initialValues }
              label='Address Line 2'
              placeholder='Apartment, suite, unit, building, floor, etc'
            />
          </Cell>

          <Cell mods='u-size1of1'>
            <FieldInput
              name='addressCity'
              validate={ this.validator }
              initialValues={ initialValues }
              label='City'
              placeholder='Enter City'
              fieldWidth='75%'
            />
          </Cell>

          <Cell mods='u-size1of4'>
            <FieldInput
              name='addressState'
              validate={ this.validator }
              initialValues={ initialValues }
              label='State'
              placeholder='Enter State'
              type={ paymentAccount.account.country === 'US' ? 'stateSelect' : 'provinceSelect' }
            />
          </Cell>

          <Cell mods='u-size3of8'>
            <FieldInput
              name='addressPostalCode'
              validate={ this.validator }
              initialValues={ initialValues }
              label='Postal Code'
              placeholder='Enter Postal Code'
            />
          </Cell>

          <Cell mods='u-size1of1'>
            <FieldLabel>Date of Birth</FieldLabel>
            <Grid isWithGutter>
              <Cell mods='u-size1of3'>
                <FieldInput
                  name='dobMonth'
                  validate={ this.validator }
                  initialValues={ initialValues }
                  type='monthSelect'

                />
              </Cell>
              <Cell mods='u-size1of3'>
                <FieldInput
                  name='dobDay'
                  validate={ this.validator }
                  initialValues={ initialValues }
                  type='daySelect'

                />
              </Cell>
              <Cell mods='u-size1of3'>
                <FieldInput
                  name='dobYear'
                  validate={ this.validator }
                  initialValues={ initialValues }
                  type='yearSelect'

                />
              </Cell>
            </Grid>
          </Cell>

          <Cell mods='u-size1of1'>
            <FieldInput
              name='idNumber'
              validate={ this.validator }
              initialValues={ initialValues }
              label='Social Security Number'
              placeholder='xxx-xx-xxxx'
              fieldWidth='25%'
              toolTipText='For your protection, we validate the identity of the recipients of the collected funds. Your social security number enables us to do that.'
            />
          </Cell>

          <Cell mods='u-size1of1'>
            <StyledPanelHeader title='Non-Profit Information' subtitle='(we must validate your legal entity)' />
          </Cell>

          <Cell mods='u-size3of4'>
            <FieldInput
              name='companyName'
              validate={ this.validator }
              initialValues={ initialValues }
              label='Non-Profit Legal Name'
              placeholder='Enter Name'
              toolTipText='The legal name of an organization is the official name of the entity that owns the organization. The legal name of the organization is the name registered with the state filing office.'
            />
          </Cell>

          <Cell mods='u-size3of4'>
            <FieldInput
              name='companyPhone'
              validate={ this.validator }
              initialValues={ initialValues }
              label='Phone Number'
              placeholder='000-000-0000'
            />
          </Cell>

          <Cell mods='u-size1of1'>
            <StyledSubmitButton isDisabled={ submitting } icon='check' color='primary'>
              Update Account
            </StyledSubmitButton>
          </Cell>
        </Grid>
      </Form>
    );
  }
}

StaticAccountsForm.propTypes = {
  paymentAccount: PropTypes.shape(paymentAccountPropShape).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  submitting: PropTypes.bool.isRequired
};

export default reduxForm({ form: 'accountUpdate' })(StaticAccountsForm);
