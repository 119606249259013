import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Cell, Button, Icon } from '@teamsnap/teamsnap-ui';
import './DatePicker.css';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate, defaultDateFormat } from 'utils/date';

class DatePicker extends Component {
  state = {
    startDate: null,
    endDate: null
  }

  handleClick = () => this.props.onButtonClick(this.state)

  handleChangeStart = (date) => {
    const { onChangeStart } = this.props;

    this.setState({ startDate: date });
    return onChangeStart && onChangeStart(date);
  }

  handleChangeEnd = (date) => {
    const { onChangeEnd } = this.props;

    this.setState({ endDate: date });
    return onChangeEnd && onChangeEnd(date);
  }

  render () {
    const {
      isRange,
      onButtonClick,
      buttonText,
      placeholderTextStart,
      placeholderTextEnd
    } = this.props;

    const dayPickerProps = {
      modifiersStyles: {
        today: { color: '#1A6BAF' }
      },
      showOutsideDays: true
    };

    const inputProps = { className: 'Input' };
    const disabledDaysStart = this.state.endDate ? { after: new Date(this.state.endDate) } : null;
    const disabledDaysEnd = this.state.startDate ? { before: new Date(this.state.startDate) } : null;

    return (
      <Grid isAlignMiddle>
        <Cell mods='u-sizeFit'>
          <DayPickerInput
            format={ defaultDateFormat }
            onDayChange={ this.handleChangeStart }
            inputProps={ { ...inputProps, placeholder: placeholderTextStart } }
            formatDate={ formatDate }
            parseDate={ parseDate }
            style={ { borderRadius: '15px' } }
            dayPickerProps={ { ...dayPickerProps, disabledDays: disabledDaysStart } }
          />
        </Cell>

        { isRange &&
          <Cell mods='u-sizeFit u-spaceLeftSm'>
            <Icon name='arrow-right' style={ { color: '#aaa' } } />
          </Cell> }

        { isRange &&
          <Cell mods='u-sizeFit u-spaceLeftSm u-spaceRightSm'>
            <DayPickerInput
              format={ defaultDateFormat }
              onDayChange={ this.handleChangeEnd }
              inputProps={ { ...inputProps, placeholder: placeholderTextEnd } }
              formatDate={ formatDate }
              parseDate={ parseDate }
              style={ { borderRadius: '15px' } }
              dayPickerProps={ { ...dayPickerProps, disabledDays: disabledDaysEnd } }
            />
          </Cell> }

        { onButtonClick &&
          <Button
            onClick={ this.handleClick }
            color='primary'
            mods='u-spaceLeftSm'
            style={ { height: '27px', lineHeight: '24px', padding: '0 12px' } }
          >
            { buttonText }
          </Button> }
      </Grid>
    );
  }
}

DatePicker.propTypes = {
  isRange: PropTypes.bool,
  onButtonClick: PropTypes.func,
  onChangeStart: PropTypes.func,
  onChangeEnd: PropTypes.func,
  buttonText: PropTypes.string,
  placeholderTextStart: PropTypes.string,
  placeholderTextEnd: PropTypes.string
};

DatePicker.defaultProps = {
  isRange: false,
  onButtonClick: null,
  onChangeStart: null,
  onChangeEnd: null,
  buttonText: 'Search',
  placeholderTextStart: null,
  placeholderTextEnd: null
};

export default DatePicker;
