import React, { Component } from 'react';

class Dashboard extends Component {
  render () {
    return (
      <p>
        TeamSnap Payments Dashboard - Coming Soon
      </p>
    );
  }
}

export default Dashboard;
