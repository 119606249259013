import { connect } from 'react-redux';
import { selectCharges, selectChargePaginate, selectChargesLoading } from 'state/charges/selectors';
import { fetchCharges, fetchNextCharges, fetchPrevCharges } from 'state/charges/actions';
import { selectPaymentAccountIsLoading } from 'state/paymentAccounts/selectors';
import Payments from './Payments';

const mapDispatchToProps = {
  fetchCharges,
  fetchNextCharges,
  fetchPrevCharges
};

const mapStateToProps = (state, props) => ({
    charges: selectCharges(state),
    paginate: selectChargePaginate(state),
    chargesLoading: selectChargesLoading(state),
    paymentAccountIsLoading: selectPaymentAccountIsLoading(state),
  });

export default connect(mapStateToProps, mapDispatchToProps)(Payments);
