import { connect } from 'react-redux';
import { fetchPayouts, fetchNextPayouts, fetchPrevPayouts } from 'state/payouts/actions';
import { selectPayouts, selectPayoutPaginate, selectPayoutsLoading } from 'state/payouts/selectors';
import Deposits from './Deposits';

const mapDispatchToProps = {
  fetchPayouts,
  fetchNextPayouts,
  fetchPrevPayouts
};

const mapStateToProps = (state) => ({
  payouts: selectPayouts(state),
  paginate: selectPayoutPaginate(state),
  payoutsLoading: selectPayoutsLoading(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(Deposits);
