// ----------------------------------------------------------------------------------------------------
// :: Action Types
// ----------------------------------------------------------------------------------------------------
export const SHOW_PORTAL = 'app/portal/SHOW';
export const HIDE_PORTAL = 'app/portal/HIDE';
export const SHOW_FLASH_MESSAGE = 'app/flashMessage/SHOW';
export const HIDE_FLASH_MESSAGE = 'app/flashMessage/HIDE';
export const SET_FEATURE_FLAG = 'app/featureFlags/SET_FEATURE_FLAG';

// ----------------------------------------------------------------------------------------------------
// :: Action Creators
// ----------------------------------------------------------------------------------------------------
export const showPortal = (portalComponent, portalProps) => ({
  type: SHOW_PORTAL,
  payload: { portalComponent, portalProps },
});

export const hidePortal = () => ({
  type: HIDE_PORTAL,
});

export const showFlashMessage = (flashMessageProps = {}) => ({
  type: SHOW_FLASH_MESSAGE,
  payload: flashMessageProps,
});

export const hideFlashMessage = () => ({
  type: HIDE_FLASH_MESSAGE,
});
