import React from 'react';
import PropTypes from 'prop-types';
import { linkToPayments, linkToDeposits, linkToOutstandingPayments } from 'utils/links';
import Tabs from 'components/baseComponents/Tabs';

const ReportsHeader = ({ params }) => {
  const financialTabs = (!('teamId' in params)) ? (
    [
      { to: linkToPayments(params), label: 'Payments' },
      { to: linkToDeposits(params), label: 'Deposits' },
      { to: linkToOutstandingPayments(params), label: 'Outstanding' }
    ]
  ) : (
    [
      { to: linkToPayments(params), label: 'Payments' },
      { to: linkToDeposits(params), label: 'Deposits' }
    ]
  );
  return (
    <div style={ { marginTop: '20px', marginBottom: '17px' } }>
      <Tabs tabs={ financialTabs }/>
    </div>
  );
};
ReportsHeader.propTypes = {
  params: PropTypes.shape({}).isRequired
};

export default ReportsHeader;
