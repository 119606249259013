import { getDivision } from 'services/payments';

// ----------------------------------------------------------------------------------------------------
// :: Action Types
// ----------------------------------------------------------------------------------------------------
export const DIVISION = 'DIVISION';
const FETCH_DIVISION = 'FETCH_DIVISION';
const SAVE_DIVISION = 'SAVE_DIVISION';
const ERROR_DIVISION = 'ERROR_DIVISION';

// ----------------------------------------------------------------------------------------------------
// :: Action Creators
// ----------------------------------------------------------------------------------------------------
export const fetchDivision = divisionId => async dispatch => {
  dispatch({ type: FETCH_DIVISION });
  try {
    const response = await getDivision(divisionId);
    return dispatch({
      type: SAVE_DIVISION,
      payload: response.data
    });
  } catch (error) {
    dispatch({ type: ERROR_DIVISION, payload: error.error });
    return error;
  }
};
