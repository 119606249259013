import React from 'react';
import PropTypes from 'prop-types';
import Select from './Select';
import { metaPropShape } from 'interfaces/field';
import FieldError from 'components/teamsnapUIExtensions/FieldError';

const MonthSelect = ({ meta, input }) => {
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const options = months.map((x, i) => ({ label: x, value: (i + 1).toString() }));

  return (
    <>
      <Select field={ input } options={ [{ label: 'Select Month', value: '' }, ...options] } />
      { meta.error && meta.touched && <FieldError>{ meta.error }</FieldError> }
    </>
  );
};

MonthSelect.propTypes = {
  meta: PropTypes.shape(metaPropShape).isRequired,
  input: PropTypes.shape({}).isRequired
};

export default MonthSelect;
