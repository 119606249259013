import { connect } from 'react-redux';
import ExportButton from './ExportButton';
import { fetchAllCharges, fetchAllPayouts, fetchAllPayoutTransactions } from 'state/exportData/actions';
import { selectExportData, selectExportDataIsLoading } from 'state/exportData/selectors';

const mapStateToProps = state => ({
  exportData: selectExportData(state),
  dataIsLoading: selectExportDataIsLoading(state)
});

const mapDispatchToProps = {
  fetchAllCharges,
  fetchAllPayouts,
  fetchAllPayoutTransactions
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportButton);
