import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReportsHeader from 'components/baseComponents/ReportsHeader';
import { ReportsTableWithPagination } from 'components/baseComponents/ReportsTable';

const columns = [
  { name: 'dueDate', label: 'Date', isSortable: false },
  {
    name: 'sourceName',
    label: 'Source',
    isSortable: false,
    render: (column, row) => (
      <>
        <a href={ row.referenceObjectUrl }>{ row[column.name] }</a>
        <span style={ { display: 'block' } }>
          { row.members }
        </span>
      </>
    )
  },
  { name: 'referenceObjectType', label: 'Type', isSortable: false },
  { name: 'status', label: 'Status', isSortable: false },
  { name: 'amountDue', label: 'Amount Due', isSortable: false }
];

const fetch = (divisionId, fetchOutstandingPaymentsByDivision) => fetchOutstandingPaymentsByDivision(divisionId);
const formatRow = (outstandingPayments) => outstandingPayments;

const buildCSVRow = (row) => ({
    Date: row.dueDate,
    Members: row.members,
    'Source Name': row.sourceName,
    'Source URL': row.referenceObjectUrl,
    Type: row.referenceObjectType,
    Status: row.status,
    'Amount Due': row.amountDue
  });

const OutstandingPayments = ({
  outstandingPayments,
  outstandingPaymentsLoading,
  fetchOutstandingPaymentsByDivision,
  match
}) => {
  const { params } = match;
  const divisionId = params.divisionId;

  useEffect(() => {
    if (Object.keys(outstandingPayments).length === 0 && !outstandingPaymentsLoading) {
      fetch(divisionId, fetchOutstandingPaymentsByDivision);
    }
  }, [outstandingPayments, outstandingPaymentsLoading, fetchOutstandingPaymentsByDivision, divisionId]);

  let rows = [];

  if (Object.keys(outstandingPayments).length > 0) {
    rows = outstandingPayments.data.length > 0 ? outstandingPayments.data : [];
  }
  const isLoaded = outstandingPayments && (Boolean(outstandingPayments.data) || outstandingPayments.isError);
  return (
    <>
      <ReportsHeader params={ params } />
      <ReportsTableWithPagination
        isLoaded={ isLoaded }
        columns={ columns }
        rows={ rows }
        fetch={ fetch }
        datatype='outstandingPayment'
        formatRow={ formatRow }
        paginate={ { hasNext: false, hasPrev: false } }
        buildCSVRow={ buildCSVRow }
      />
    </>
  );
};

OutstandingPayments.propTypes = {
  outstandingPayments: PropTypes.shape({
    data: PropTypes.array,
    meta: PropTypes.object,
    request: PropTypes.object,
    isError: PropTypes.bool,
  }),
  outstandingPaymentsLoading: PropTypes.bool.isRequired,
  fetchOutstandingPaymentsByDivision: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired
};

OutstandingPayments.defaultProps = {
  outstandingPayments: {}
};

export default OutstandingPayments;
