import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@teamsnap/teamsnap-ui';
import { FileService, FileType } from 'frontend-toolkit';

const FileExport = ({ name, data, type, label, isLoading }) => {
  const linkRef = useRef();
  const [csvURL, setCsvURL] = useState(null);
  
  useEffect(() => {
    if (csvURL && linkRef.current) {
      linkRef.current.click();
    }
  }, [csvURL]);
  
  const handleOnClick = () => {
    isLoading(true);
    const fileType = type.toUpperCase();
    const csvBlob = FileService.create(data, FileType[fileType]);

    setCsvURL(URL.createObjectURL(csvBlob));

    isLoading(false);
  };

  const fileName = name.length > 0 ? name : `reporting_${Date.now()}`;

  return (
    <>
      <Button
        onClick={ handleOnClick }
        isDisabled={ data.length === 0 }
      >
        { label }
      </Button>
      { data.length > 0 && ( 
        <a 
          style={ { display: 'none' } } 
          href={ csvURL } 
          download={ `${fileName}.csv` } 
          ref={ linkRef } 
        >
        { label }
        </a> 
      ) }
    </>
  );
};

FileExport.propTypes = {
  name: PropTypes.string,
  data: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isLoading: PropTypes.func
};

FileExport.defaultProps = {
  name: '',
  isLoading: () => false
};

export default FileExport;
