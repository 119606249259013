import { featureRollout, isTeam } from './window';

const rootPath = window.location.href.includes('league')
  ? '/:divisionId/league_financials'
  : '/:teamId/team_financials';

const rootPathInvoicing = window.location.href.includes('league')
  ? '/:divisionId/league_payments'
  : '/:teamId/team_payments';

const linkTo = (path, params) => path
  .replace(/:divisionId/g, params.divisionId)
  .replace(/:teamId/g, params.teamId)
  .replace(/:accountId/g, params.accountId)
  .replace(/:depositId/g, params.depositId);

export const routes = {
  root: {
    path: rootPath,
    fullPath: rootPath,
    url: params => linkTo(rootPath, params)
  },
  dashboard: {
    path: '/dashboard',
    fullPath: `${rootPath}/dashboard`,
    url: params => linkTo(`${rootPath}/dashboard`, params)
  },
  accounts: {
    path: '/accounts',
    fullPath: `${rootPath}/accounts`,
    url: params => linkTo(`${rootPath}/accounts`, params)
  },
  accountsNew: {
    path: '/accounts/new',
    fullPath: `${rootPath}/accounts/new`,
    url: params => linkTo(`${rootPath}/accounts/new`, params)
  },
  accountsEdit: {
    path: '/accounts/:accountId/edit',
    fullPath: `${rootPath}/accounts/:accountId/edit`,
    url: params => linkTo(`${rootPath}/accounts/:accountId/edit`, params)
  },
  updateIdNumber: {
    path: '/update_id_number',
    fullPath: `${rootPath}/update_id_number`,
    url: params => linkTo(`${rootPath}/update_id_number`, params)
  },
  acceptTerms: {
    path: '/accept_terms',
    fullPath: `${rootPath}/accept_terms`,
    url: params => linkTo(`${rootPath}/accept_terms`, params)
  },
  payments: {
    path: '/reports/payments',
    fullPath: `${rootPath}/reports/payments`,
    url: params => linkTo(`${rootPath}/reports/payments`, params)
  },
  deposits: {
    path: '/reports/deposits',
    fullPath: `${rootPath}/reports/deposits`,
    url: params => linkTo(`${rootPath}/reports/deposits`, params)
  },
  depositsShow: {
    path: '/reports/deposits/:depositId',
    fullPath: `${rootPath}/reports/deposits/:depositId`,
    url: params => linkTo(`${rootPath}/reports/deposits/:depositId`, params)
  },
  outstandingPayments: {
    path: '/reports/outstanding',
    fullPath: `${rootPath}/reports/outstanding`,
    url: params => linkTo(`${rootPath}/reports/outstanding`, params)
  },
  accountsUpdate: {
    path: '/update_account',
    fullPath: `${rootPath}/update_account`,
    url: params => linkTo(`${rootPath}/update_account`, params)
  },
  // payments: {
    //   path: '/accounts/:accountId/payments',
    //   fullPath: `${rootPath}/accounts/:accountId/payments`,
    //   url: params => linkTo(`${rootPath}/accounts/:accountId/payments`, params)
    // },
    // deposits: {
      //   path: '/accounts/:accountId/deposits',
      //   fullPath: `${rootPath}/accounts/:accountId/deposits`,
      //   url: params => linkTo(`${rootPath}/accounts/:accountId/deposits`, params)
      // }
    };

const invoicingRoutes = {
  teamsAccounts: {
    path: '/invoicing/account',
    fullPath: `${rootPathInvoicing}/invoicing/account`,
    url: params => linkTo( `${rootPathInvoicing}/invoicing/account`, params)
  }
};

export const useTeamsAccounts = !!(isTeam && featureRollout('invoicing_frontend_visa_mandate_standalone'));

export const linkToRoot = params => routes.root.url(params);

export const linkToTeamsAccounts = params => invoicingRoutes.teamsAccounts.url(params);

export const linkToAccounts = params => routes.accounts.url(params);

export const linkToAccountsEdit = params => routes.accountsEdit.url(params);

export const linkToAccountsNew = (params) => routes.accountsNew.url(params);

export const linkToPayments = params => routes.payments.url(params);

export const linkToOutstandingPayments = params => routes.outstandingPayments.url(params);

export const linkToDeposits = params => routes.deposits.url(params);

export const linkToDepositsShow = params => routes.depositsShow.url(params);
