import { connect } from 'react-redux';
import { hideFlashMessage, showFlashMessage } from 'state/app/actions';
import { updateIdNumber } from 'state/idNumber/actions';
import { selectIdNumber } from 'state/idNumber/selectors';
import { selectPaymentAccount } from 'state/paymentAccounts/selectors';
import IdNumberUpdate from './IdNumberUpdate';

const mapDispatchToProps = {
  updateIdNumber,
  showFlashMessage,
  hideFlashMessage,
};

const mapStateToProps = (state, ownProps) => ({
  requestError: state.idNumber.error,
  paymentAccount: selectPaymentAccount(state),
  idNumberData: selectIdNumber(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(IdNumberUpdate);
