import { SHOW_PORTAL, HIDE_PORTAL, SHOW_FLASH_MESSAGE, HIDE_FLASH_MESSAGE, SET_FEATURE_FLAG } from './actions';

const initialState = {
  flashMessage: {
    showFlashMessage: false,
    flashMessageProps: {},
  },
  portal: {
    portalComponent: null,
    portalProps: {},
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_PORTAL:
      return {
        ...state,
        portal: {
          portalComponent: action.payload.portalComponent,
          portalProps: action.payload.portalProps,
        },
      };

    case HIDE_PORTAL:
      return {
        ...state,
        portal: initialState.portal,
      };

    case SHOW_FLASH_MESSAGE:
      return {
        ...state,
        flashMessage: {
          showFlashMessage: true,
          flashMessageProps: action.payload,
        },
      };

    case HIDE_FLASH_MESSAGE:
      return {
        ...state,
        flashMessage: initialState.flashMessage,
      };

    case SET_FEATURE_FLAG:
      return {
        ...state,
        featureFlags: {
          ...state.featureFlags,
          ...action.payload,
        },
      };

    default:
      return state;
  }
};
