import { connect } from 'react-redux';
import { fetchOutstandingPaymentsByDivision } from 'state/outstandingPayments/actions';
import { selectOutstandingPayments, selectOutstandingPaymentsLoading } from 'state/outstandingPayments/selectors';
import OutstandingPayments from './OutstandingPayments';

const mapDispatchToProps = { fetchOutstandingPaymentsByDivision };

const mapStateToProps = (state) => ({
  outstandingPayments: selectOutstandingPayments(state),
  outstandingPaymentsLoading: selectOutstandingPaymentsLoading(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(OutstandingPayments);
