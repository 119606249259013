import { connect } from 'react-redux';
import {
  selectPaymentAccount,
  selectPaymentAccountIsLoading,
  selectPaymentAccountCurrentlyDueRequirements,
  selectPaymentAccountCurrentDeadline,
  selectPaymentAccountDisabledReason,
} from 'state/paymentAccounts/selectors';
import { selectDivision } from 'state/division/selectors';
import { fetchPaymentAccountsByDivision, fetchPaymentAccount } from 'state/paymentAccounts/actions';
import { fetchDivision } from 'state/division/actions';
import { fetchCanUpdate } from 'state/canUpdate/actions';
import { selectFormFieldsAreLoading } from 'state/formFields/selectors';
import { fetchFormFields } from 'state/formFields/actions';
import { showFlashMessage, hideFlashMessage } from 'state/app/actions';
import LeagueFinancials from './LeagueFinancials';
import { selectCanUpdate } from 'state/canUpdate/selectors';

const mapDispatchToProps = {
  fetchPaymentAccountsByDivision,
  fetchDivision,
  fetchCanUpdate,
  fetchFormFields,
  showFlashMessage,
  hideFlashMessage,
  fetchPaymentAccount,
};

const mapStateToProps = (state) => ({
  paymentAccount: selectPaymentAccount(state),
  paymentAccountIsLoading: selectPaymentAccountIsLoading(state),
  formFieldsAreLoading: selectFormFieldsAreLoading(state),
  canUpdate: selectCanUpdate(state),
  paymentAccountCurrentlyDueRequirements: selectPaymentAccountCurrentlyDueRequirements(state),
  paymentAccountCurrentDeadline: selectPaymentAccountCurrentDeadline(state),
  paymentAccountDisabledReason: selectPaymentAccountDisabledReason(state),
  division: selectDivision(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(LeagueFinancials);
