import { ButtonGroup, Cell, Grid, Loader } from '@teamsnap/teamsnap-ui';
import '@teamsnap/teamsnap-ui/dist/css/themes/league.css';
import AppRoutes from 'components/baseComponents/AppRoutes';
import FlashMessage from 'components/baseComponents/FlashMessage';
import AcceptTerms from 'components/screens/AcceptTerms';
import Accounts from 'components/screens/Accounts';
import AccountsEdit from 'components/screens/AccountsEdit';
import AccountsNew from 'components/screens/AccountsNew';
import AccountsUpdate from 'components/screens/AccountsUpdate';
import Dashboard from 'components/screens/Dashboard';
import Deposits from 'components/screens/Deposits';
import DepositsShow from 'components/screens/DepositsShow';
import IdNumberUpdate from 'components/screens/IdNumberUpdate';
import OutstandingPayments from 'components/screens/OutstandingPayments';
import Payments from 'components/screens/Payments';
import { historyPropShape, locationPropShape, matchPropShape } from 'interfaces/navigation';
import { paymentAccountPropShape } from 'interfaces/paymentAccount';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Link, matchPath } from 'react-router-dom';
import { onGroupSettings } from 'utils/group';
import { linkToAccounts, linkToPayments, routes } from 'utils/links';

const appRoutes = [
  { path: routes.root.path, redirect: routes.accounts.fullPath },
  { path: routes.dashboard.path, component: Dashboard },
  { path: routes.accounts.path, component: Accounts, exact: true },
  { path: routes.accountsEdit.path, component: AccountsEdit },
  { path: routes.updateIdNumber.path, component: IdNumberUpdate },
  { path: routes.accountsNew.path, component: AccountsNew },
  { path: routes.acceptTerms.path, component: AcceptTerms },
  { path: routes.payments.path, component: Payments },
  { path: routes.deposits.path, component: Deposits, exact: true },
  { path: routes.depositsShow.path, component: DepositsShow },
  { path: routes.accountsUpdate.path, component: AccountsUpdate },
  { path: routes.outstandingPayments.path, component: OutstandingPayments, exact: true },
];

class LeagueFinancials extends Component {
  componentDidMount() {
    const {
      match: {
        params: { divisionId },
      },
    } = this.props;

    let division;

    this.props.fetchCanUpdate(divisionId);

    this.props
    .fetchDivision(divisionId)
    .then((response) => {
      division = response.payload;
      return this.props.fetchPaymentAccountsByDivision(divisionId);
    })
    .then(() => this.props.fetchPaymentAccount(
        division.formattedPersistentUuid,
        division.id
    ));
  }

  componentDidUpdate() {
    const {
      paymentAccount,
      paymentAccountIsLoading,
    } = this.props;

    if (paymentAccountIsLoading === false) {
      this.shouldPromptStageTwo(paymentAccount);
    }
  }

  shouldShowBanner = () => {
    const {
      paymentAccountCurrentlyDueRequirements,
      paymentAccountCurrentDeadline,
      paymentAccountDisabledReason,
    } = this.props;

    const hasRequirements =
      (paymentAccountCurrentlyDueRequirements && paymentAccountCurrentlyDueRequirements.length > 0) ||
      paymentAccountCurrentDeadline !== null ||
      paymentAccountDisabledReason !== null;

    return hasRequirements;
  };

  isUnderReview = () => {
    const {
      paymentAccountDisabledReason,
    } = this.props;

    const underReviewReasons = [
      'under_review',
      'requirements.pending_verification',
      'platform_paused',
      'listed'
    ];

    return underReviewReasons.includes(paymentAccountDisabledReason);
  }

  shouldPromptStageTwo(account) {
    const {
      canUpdate,
      match: {
        params: { divisionId },
      },
      location: { pathname },
    } = this.props;

    if (this.shouldShowBanner()) {
      const { organizationJsUrl, organizationId, programId, seasonId } = window.TeamSnap;

      if (this.isUnderReview()) {
        this.props.showFlashMessage({
          icon: 'notifications',
          color: 'highlight',
          title: 'Deposit Account Setup Under Review',
          children:
            <>
              Account may be under review for multiple days, during this time, payouts may be disabled.
            </>
          ,
          handleClose: this.props.hideFlashMessage,
        });
      } else {
        this.props.showFlashMessage({
          icon: 'notifications',
          color: 'highlight',
          title: 'Deposit Account Setup Pending',
          children: canUpdate ? (
            <>
              Account setup needs to be completed before you can receive payments and deposits.
              { !onGroupSettings(pathname, { divisionId }) && (
                <>
                  { ' ' }
                  { organizationId ? (
                    <a
                      href={ `${organizationJsUrl}/organizations/${organizationId}/programs/${programId}/seasons/${seasonId}/financials` }
                    >
                      Complete Account
                    </a>
                  ) : (
                    <Link
                      to={ linkToAccounts({ divisionId }) }
                      style={ { marginLeft: '10px' } }
                    >
                      Complete Account
                    </Link>
                  ) }
                </>
              ) }
            </>
          ) : (
            <>
              Account setup must be completed by an organization administrator
              before you can receive payments and deposits.
            </>
          ),
          handleClose: this.props.hideFlashMessage,
        });
      }
    }
  }

  renderButtons = () => {
    const {
      paymentAccount,
      location: { pathname },
      match: { params },
      history: { push },
      canUpdate,
    } = this.props;

    const isAccountsPath = !!matchPath(pathname, { path: routes.accounts.fullPath });

    const isReportsPath =
      !!matchPath(pathname, { path: routes.payments.fullPath, exact: true }) ||
      !!matchPath(pathname, { path: routes.deposits.fullPath, exact: true }) ||
      !!matchPath(pathname, { path: routes.outstandingPayments.fullPath, exact: true });

    const options = [];

    if (canUpdate && paymentAccount) {
      options.push({
        label: 'Financial Reporting',
        icon: 'statistics',
        isDisabled: !paymentAccount,
        isActive: isReportsPath,
        onClick: () => push(linkToPayments({ accountId: paymentAccount.id, ...params })),
      });

      const { organizationJsUrl, organizationId, programId, seasonId } = window.TeamSnap;

      const settings_option = {
        label: 'Financial Settings',
        icon: 'settings',
        isDisabled: !paymentAccount,
        isActive: isAccountsPath,
        onClick: () =>
            organizationId
              ? (window.location.href = `${organizationJsUrl}/organizations/${organizationId}/programs/${programId}/seasons/${seasonId}/financials`)
              : push(linkToAccounts(params))
      };

      options.unshift(settings_option);
      // commented this out due to issues that in redirecting to proper page
      // paymentAccount.isSetupComplete ? options.push(settings_option) : options.unshift(settings_option);
    }

    return options;
  };

  getPageHeader = () => {
    const {
      location: { pathname },
    } = this.props;

    if (matchPath(pathname, { path: routes.accounts.fullPath })) {
      return 'Financial Settings';
    } else if (
      matchPath(pathname, { path: routes.accountsUpdate.fullPath }) ||
      matchPath(pathname, { path: routes.updateIdNumber.fullPath }) ||
      matchPath(pathname, { path: routes.acceptTerms.fullPath })
    ) {
      return 'Account Update';
    } else {
      return 'Financial Reporting';
    }
  };

  render() {
    const {
      match: { path },
      paymentAccount,
      paymentAccountIsLoading,
      formFieldsAreLoading,
    } = this.props;
    return (
      <div>
        <header style={ { margin: '20px 20px 0' } }>
          <Grid>
            <Cell mods="u-sizeFill">
              <h2>{ this.getPageHeader() }</h2>
            </Cell>

            <ButtonGroup buttons={ this.renderButtons() } />
          </Grid>
        </header>

        <main className="u-padSidesMd">
          { (paymentAccountIsLoading || formFieldsAreLoading) && <Loader type="spin" text="Loading..." /> }

          <FlashMessage />

          { (
            (!paymentAccountIsLoading && !formFieldsAreLoading && paymentAccount) ||
            (!paymentAccountIsLoading && !paymentAccount)) && (
              <AppRoutes routes={ appRoutes } rootPath={ path } paymentAccount={ paymentAccount } />
            )
          }
        </main>
      </div>
    );
  }
}

LeagueFinancials.propTypes = {
  match: PropTypes.shape(matchPropShape).isRequired,
  history: PropTypes.shape(historyPropShape).isRequired,
  location: PropTypes.shape(locationPropShape).isRequired,
  fetchPaymentAccountsByDivision: PropTypes.func.isRequired,
  fetchFormFields: PropTypes.func.isRequired,
  showFlashMessage: PropTypes.func.isRequired,
  hideFlashMessage: PropTypes.func.isRequired,
  fetchCanUpdate: PropTypes.func.isRequired,
  paymentAccount: PropTypes.shape(paymentAccountPropShape),
  paymentAccountIsLoading: PropTypes.bool,
  formFieldsAreLoading: PropTypes.bool,
  canUpdate: PropTypes.bool,
};

LeagueFinancials.defaultProps = {
  paymentAccount: null,
  paymentAccountIsLoading: true,
  formFieldsAreLoading: true,
  canUpdate: false,
};

export default LeagueFinancials;
