import React from 'react';
import PropTypes from 'prop-types';

const ReportsAnchorTag = ({ href }) => {
  const title = href.includes('registration') ? 'View Registration' : 'View Invoice';

  return <a href={ href }>{ title }</a>;
};

ReportsAnchorTag.propTypes = {
  href: PropTypes.string.isRequired
};

export default ReportsAnchorTag;
