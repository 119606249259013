import { repeatCharacter } from 'utils/helpers';

const initializeAccountNumber = (values, isEditing, initialValues) => {
  const { bankAccount } = values;
  if (!isEditing && bankAccount && bankAccount.last4) {
    initialValues.accountNumber = `${repeatCharacter(4, '\u2022')} ${bankAccount.last4}`;
  } else {
    initialValues.accountNumber = '';
  }
  initialValues.accountNumber2 = '';
  return initialValues;
};

const initializeRoutingNumber = (values, isEditing, initialValues) => {
  const { bankAccount } = values;
  if (!isEditing && bankAccount && bankAccount.routingNumber) {
    initialValues.routingNumber = bankAccount.routingNumber;
  } else {
    initialValues.routingNumber = '';
  }
  return initialValues;
};

const initializeCompanyName = (values, _isEditing, initialValues) => {
  const { account } = values;
  if (account.company) {
    initialValues.companyName = account.company.name;
  } else {
    initialValues.companyName = '';
  }
  return initialValues;
};

const initializeDob = (_values, _isEditing, initialValues) => {
  initialValues.dobDay = '';
  initialValues.dobMonth = '';
  initialValues.dobYear = '';
  return initialValues;
};

const fieldsToInitialValues = {
  accountNumber: initializeAccountNumber,
  routingNumber: initializeRoutingNumber,
  companyName: initializeCompanyName,
  dob: initializeDob
};

const initialValueBuilder = (values, isEditing, fields) => {
  let initialValues = {};
  if (fields) {
    fields.forEach(field => {
      if (fieldsToInitialValues[field.formattedName]) {
        initialValues = fieldsToInitialValues[field.formattedName](values, isEditing, initialValues);
      } else {
        initialValues[field.formattedName] = '';
      }
    });
  }
  return initialValues;
};

export default initialValueBuilder;
