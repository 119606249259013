import { camelCase } from './underdash';

export const combineFormFields = fieldGroups => {
  const combinedFields = [];
  if (fieldGroups) {
    fieldGroups.forEach(fieldGroup => {
      if (fieldGroup.fields) {
        combinedFields.push(...fieldGroup.fields);
      }
    });
  }
  return combinedFields;
};

export const formatFormFieldName = value => {
  const preppedName = value.replace(/external_account\./, '').replace(/account_opener\./, '').replace(/individual\./, '').replace(/\./, '_');
  return camelCase(preppedName);
};

export const formatValueName = value => {
  const trimmedValue = value.trim();
  if (!trimmedValue) return '';
  const upperCased = trimmedValue.replace(trimmedValue[0], trimmedValue[0].toUpperCase());
  // Split camelCased names into words separated by a space
  return upperCased.match(/[A-Z0-9][a-z]*/g).join(' ').replace('Dob', 'DOB');
};

export const extractNonRequiredFields = fields => {
  if (!fields) return [];
  const combinedFields = combineFormFields(fields);
  const nonRequiredFields = [];
  combinedFields.forEach(field => {
    if (!field.isRequired) {
      nonRequiredFields.push(formatFormFieldName(field.name));
    }
  });
  return nonRequiredFields;
};

export const determineFieldSize = (name) => {
  if (name.match(/addressline/i)) {
    return 'u-size1of1';
  } else if (name.match(/postalcode/i)) {
    return 'u-size1of4';
  } else {
    return 'u-size3of4';
  }
};

export const determineFieldDisplayType = field => {
  if (field.confirm === true) return 'confirm';
  if (field.inputType === 'date') return 'date';
  if (['Province', 'State'].includes(field.label)) return 'state';
  if (field.name.match(/city/i) || field.name.match(/ssn_last/i)) return 'breakpoint';
  return 'normal';
};

export const formatFormFieldData = formFieldGroups => {
  const sortedFieldGroups = formFieldGroups.sort((x, y) => x.order - y.order);
  sortedFieldGroups.forEach(fieldGroup => {
    fieldGroup.fields.sort((x, y) => x.order - y.order);
    fieldGroup.fields.forEach(field => {
      field.displayType = determineFieldDisplayType(field);
      field.formattedName = formatFormFieldName(field.name);
    });
  });
  return sortedFieldGroups;
};
