import { connect } from 'react-redux';
import { selectPaymentAccount } from 'state/paymentAccounts/selectors';
import { updatePaymentAccountWithFormFields } from 'state/paymentAccounts/actions';
import { showFlashMessage, hideFlashMessage } from 'state/app/actions';
import { selectFormFields } from 'state/formFields/selectors';
import { fetchFormFields } from 'state/formFields/actions';
import AccountsEdit from './AccountsEdit';

const mapDispatchToProps = {
  updatePaymentAccountWithFormFields,
  showFlashMessage,
  hideFlashMessage,
  fetchFormFields
};

const mapStateToProps = (state, ownProps) => ({
  requestError: state.paymentAccounts.error,
  paymentAccount: selectPaymentAccount(state),
  formFieldsBySection: selectFormFields(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountsEdit);
