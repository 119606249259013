import React from 'react';
import PropTypes from 'prop-types';
import Select from './Select';
import { range } from 'utils/underdash';
import FieldError from 'components/teamsnapUIExtensions/FieldError';
import { metaPropShape } from 'interfaces/field';

const YearSelect = ({ input, meta }) => {
  const options = range(1920, new Date().getFullYear() - 17).reverse().map(x => (
    { label: x.toString(), value: x.toString() }
  ));

  return (
    <>
      <Select field={ input } options={ [{ label: 'Select Year', value: '' }, ...options] } />
      { meta.error && meta.touched && <FieldError>{ meta.error }</FieldError> }
    </>
  );
};

YearSelect.propTypes = {
  input: PropTypes.shape({}).isRequired,
  meta: PropTypes.shape(metaPropShape).isRequired
};

export default YearSelect;
