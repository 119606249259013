import AXIOS from 'axios';
import { objectKeysToCase } from 'utils/helpers';
import { paymentsApiUrl, authToken } from 'utils/window';

const API_VERSION = 'v1.0.0';

const axios = AXIOS.create({
  baseURL: paymentsApiUrl || process.env.REACT_APP_PAYMENTS_API_URL,
  headers: {
    Accept: `application/vnd.api.${API_VERSION}+json`,
    ...(authToken && { authorization: `Bearer ${authToken}` }),
  },
  withCredentials: true,
});

// On Request, Add Start Time and convert params/data to snake_case
axios.interceptors.request.use((config) => ({
  ...config,
  startTime: new Date().getTime(),
  params: objectKeysToCase(config.params, true),
  data: config.data,
}));

// On Response, Add End Time and convert params/data to camelCase
axios.interceptors.response.use((response) => ({
  ...response,
  data: objectKeysToCase(response.data),
  config: {
    ...response.config,
    endTime: new Date().getTime(),
  },
}));

const onSuccess = ({ config, data }) => {
  const response = {
    data: data.data,
    meta: data.meta,
    request: {
      url: config.url,
      params: config.params,
      method: config.method,
      headers: config.headers,
      data: config.data ? JSON.parse(config.data) : null,
      time: `${config.endTime - config.startTime} ms`,
    },
  };

  console.log('Request Successful', response);
  return response;
};

const onError = (error) => {
  let errors = {};

  // Catch Server Not Responding (Network Error)
  if (!error.response) {
    // TODO: This should be formatted like the errors from our API
    errors = {
      error: { message: 'Error: Network Error' },
      meta: 'Connection Refused: Network Error',
      message: 'Error: Network Error',
      request: { url: null, params: null, method: null, headers: null, data: null },
    };
  } else {
    const {
      message,
      response: { config, data },
    } = error;

    errors = {
      error: data.error,
      meta: data.meta,
      message: message,
      request: {
        url: config.url,
        params: config.params,
        method: config.method,
        headers: config.headers,
        data: config.data ? JSON.parse(config.data) : null,
      },
    };
  }

  console.log('Request Failed', errors);
  return Promise.reject(errors);
};

const request = (method, url, params) =>
  // let requestParams = { data: params }
  // if (method === 'get' || method === 'delete') { requestParams = { params } }

  axios({ method, url, params }).then(onSuccess).catch(onError);

const formFieldRequest = (method, url, data) =>
  axios({ method, url, data: { data, metadata: { form_fields: true } } })
    .then(onSuccess)
    .catch(onError);

const paymentAccountRequest = (method, url, data) =>
  axios({ method, url, data }).then(onSuccess).catch(onError);

export const api = {
  get(url, params) {
    return request('get', url, params);
  },
  post(url, params) {
    return request('post', url, params);
  },
  put(url, params) {
    return request('put', url, params);
  },
  patch(url, params) {
    return request('patch', url, params);
  },
  delete(url, params) {
    return request('delete', url, params);
  },
  patchFormFields(url, params) {
    return formFieldRequest('patch', url, params);
  },
};
