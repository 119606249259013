import { connect } from 'react-redux';
import { selectFormFields } from 'state/formFields/selectors';
import { selectPaymentAccount, selectPaymentAccountIsLoading } from 'state/paymentAccounts/selectors';
import { selectDivision } from 'state/division/selectors';
import { selectOnboardingLinkIsLoading } from 'state/onboardingLink/selectors';
import { createOnboardingLink } from 'state/onboardingLink/actions';
import Accounts from './Accounts';

const mapDispatchToProps = {
  createOnboardingLink,
};

const mapStateToProps = (state, ownProps) => ({
  formFieldsBySection: selectFormFields(state),
  paymentAccount: selectPaymentAccount(state),
  division: selectDivision(state),
  paymentAccountIsLoading: selectPaymentAccountIsLoading(state),
  onboardingLinkIsLoading: selectOnboardingLinkIsLoading(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Accounts);
