import PropTypes from 'prop-types';

export const matchPropShape = {
  params: PropTypes.shape({
    accountId: PropTypes.string
  }),
  path: PropTypes.string
};

export const historyPropShape = {
  push: PropTypes.func
};

export const locationPropShape = {
  pathname: PropTypes.string
};

export const paginatePropShape = {
  hasNext: PropTypes.bool,
  hasPrev: PropTypes.bool
};
