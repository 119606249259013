import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Cell, Table, ButtonGroup } from '@teamsnap/teamsnap-ui';
import DatePicker from 'components/teamsnapUIExtensions/DatePicker';
import ExportButton from 'components/baseComponents/ExportButton';
import { paginatePropShape } from 'interfaces/navigation';

class ReportsTable extends Component {
  state = {
    startDate: null,
    endDate: null
  }

  componentDidMount = () => {
    if (this.props.dataType === 'payoutTransactions') {
      this.props.fetch();
    }
  }

  handleClick = (date) => {
    if ((this.state.startDate !== date.startDate) || (this.state.endDate !== date.endDate)) {
      this.setState({
        startDate: date.startDate,
        endDate: date.endDate
      });
    }

    return this.props.fetch(date);
  }

  handleDateChangeStart = (date) => {
    if (this.state.startDate !== date.startDate) {
      this.setState({
        startDate: date
      });
    }
  }

  handleDateChangeEnd = (date) => {
    if (this.state.endDate !== date.endDate) {
      this.setState({
        endDate: date
      });
    }
  }

  handleClickNext = () => this.props.fetch(this.state, 'next')

  handleClickPrev = () => this.props.fetch(this.state, 'prev')

  formatColumnsForExport = () => {
    const { columns, dataType } = this.props;
    const columnsForButton = [...columns];
    if (dataType === 'payouts') {
      columnsForButton.unshift({ name: 'id', label: 'Payout ID' });
    } else {
      columnsForButton.unshift({ name: 'stripesChargeId', label: 'Charge ID' });
      columnsForButton.push({ name: 'participant', label: 'Participant Name' }, { name: 'payerName', label: 'Payer Name' });
    }
    return columnsForButton;
  }

  render () {
    const {
      heading,
      columns,
      rows,
      paginate,
      loading,
      dataType,
      formatRows,
      payoutId
    } = this.props;

    const {
      startDate,
      endDate
    } = this.state;

    return (
      <div style={ { overflow: 'visible' } }>
        <Grid isAlignMiddle isWithGutter mods="u-spaceTopSm">
          { !heading &&
            <Cell mods='u-sizeFill'>
              <DatePicker
                isRange
                placeholderTextStart='Select Start Date'
                placeholderTextEnd='Select End Date'
                onButtonClick={ this.handleClick }
                onChangeStart={ this.handleDateChangeStart }
                onChangeEnd={ this.handleDateChangeEnd }
              />
            </Cell> }

          { heading &&
            <Cell mods='u-sizeFill'>
              <h3>{ heading }</h3>
            </Cell> }

          <ExportButton
            rows={ rows }
            columns={ this.formatColumnsForExport() }
            formatRows={ formatRows }
            dataType={ dataType }
            payoutId={ payoutId }
            startDate={ startDate }
            endDate={ endDate }
          />

          <Cell mods='u-sizeFit'>
            <ButtonGroup buttons={ [
              { label: 'Prev', isDisabled: loading || !paginate.hasPrev, onClick: this.handleClickPrev },
              { label: 'Next', isDisabled: loading || !paginate.hasNext, onClick: this.handleClickNext }
            ] }
            />
          </Cell>
        </Grid>

        <Table
          placeHolder={ startDate == null && endDate == null ? 'No activity within the last 30 days. Adjust the date range to find payment activity.' : 'No activity during this period. Adjust the date range to find payment activity.' }
          columns={ columns }
          rows={ !loading ? rows : [] }
          isLoading={ loading }
          mods='u-spaceTopSm'
        />
      </div>
    );
  }
}

ReportsTable.propTypes = {
  fetch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  paginate: PropTypes.shape(paginatePropShape).isRequired,
  heading: PropTypes.string,
  dataType: PropTypes.string,
  formatRows: PropTypes.func,
  payoutId: PropTypes.string
};

ReportsTable.defaultProps = {
  heading: null,
  dataType: '',
  formatRows: null,
  payoutId: null
};

export default ReportsTable;
