import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { LabelButton, Status } from '@teamsnap/snap-ui';
import { getGroupParams } from 'utils/group';

import { paymentAccountPropShape } from 'interfaces/paymentAccount';
import { matchPropShape, historyPropShape } from 'interfaces/navigation';
import { Redirect } from 'react-router-dom';
import { linkToAccountsEdit, linkToAccountsNew, linkToTeamsAccounts, useTeamsAccounts } from 'utils/links';

class Accounts extends Component {
  redirectToEdit = (_values, _other) => {
    const {
      history,
      paymentAccount,
      match: { params },
    } = this.props;

    return history.push(linkToAccountsEdit({ ...getGroupParams(params), accountId: paymentAccount.id }));
  };

  redirectToOnboarding = async () => {
    const { paymentAccount, createOnboardingLink, division } = this.props;
    const createdOnboardingLinkResponse = await createOnboardingLink(paymentAccount.paymentsServiceId, {
      refresh_url: window.location.href,
      return_url: window.location.href,
      type: 'account_onboarding',
      division_id: division.id
    });

    if (createdOnboardingLinkResponse) {
      const url = createdOnboardingLinkResponse.payload.url;

      if (url) {
        window.location.href = url;
      }
    }
  };

  getPaymentAccountStatus() {
    const { paymentAccount } = this.props;
    const paymentAccountStatus = paymentAccount.status;

    if (paymentAccountStatus === 'approved') {
      return <Status state="success" text="Approved" />;
    } else if (paymentAccountStatus === 'under_review') {
      return <Status state="negative" text="Under review" />;
    } else if (paymentAccountStatus === 'information_required') {
      return <Status state="negative" text="Information required" />;
    } else if (paymentAccountStatus === 'bank_details_required') {
      return <Status state="negative" text="Bank details required" />;
    } else if (paymentAccountStatus === 'restricted_soon') {
      return <Status state="warning" text="Restricted soon" />;
    } else if (paymentAccountStatus === 'rejected') {
      return <Status state="negative" text="Rejected" />;
    }
  }

  render() {
    const {
      paymentAccount,
      match: { params },
      paymentAccountIsLoading,
      onboardingLinkIsLoading,
    } = this.props;

    // specifically check for 'null' means we've checked and there is no payment account
    if (paymentAccount === null && !paymentAccountIsLoading) {
      return <Redirect to={ linkToAccountsNew({ ...params }) } />;
    }

    // standalone teams use the new accounts flow in invoicing-frontend
    if (useTeamsAccounts) {
      window.location.assign(linkToTeamsAccounts({ ...params }));
    }

    return (
      <>
        { paymentAccount && !paymentAccountIsLoading && (
          <>
            <div
              style={ { maxWidth: 600 } }
              className="sui-bg-white sui-my-2 sui-py-1 sui-px-2 sui-rounded sui-border sui-border-neutral-border sui-border-solid sui-mx-auto"
            >
              <div className="sui-flex sui-items-center sui-justify-between">
                <div>
                  <div
                    data-testid="SeasonMerchantAccount--account-option-name"
                    className="sui-body-dense sui-text-neutral-text"
                  >
                    { paymentAccount.nickname }
                  </div>
                  <div className="sui-flex sui-items-center sui-mt-0.5">
                    { paymentAccount.bankAccountNumberLast4 && (
                      <div
                        data-testid="SeasonMerchantAccount--account-option-last-four"
                        className="sui-caption sui-text-neutral-text-medium sui-pr-1 sui-mt-1"
                      >
                        *{ paymentAccount?.bankAccountNumberLast4 }
                      </div>
                    ) }
                    { this.getPaymentAccountStatus() }
                  </div>
                </div>
                <div className="sui-flex sui-items-center sui-justify-end">
                  { (paymentAccount.status === 'approved' ||
                    paymentAccount.status === 'restricted_soon' ||
                    paymentAccount.status === 'information_required' ||
                    paymentAccount.status === 'bank_details_required') && (
                    <LabelButton onClick={ this.redirectToOnboarding } disabled={ onboardingLinkIsLoading }>
                      { paymentAccount.status === 'approved' && 'Update account' }
                      { (paymentAccount.status === 'information_required' ||
                        paymentAccount.status === 'restricted_soon') &&
                        'Verify account' }
                      { paymentAccount.status === 'bank_details_required' && 'Connect account' }
                    </LabelButton>
                  ) }
                </div>
              </div>
            </div>
          </>
        ) }
      </>
    );
  }
}

Accounts.propTypes = {
  match: PropTypes.shape(matchPropShape).isRequired,
  history: PropTypes.shape(historyPropShape).isRequired,
  paymentAccount: PropTypes.shape(paymentAccountPropShape),
  formFieldsBySection: PropTypes.array,
};

Accounts.defaultProps = {
  formFieldsBySection: [],
};

export default Accounts;
