import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Panel, Grid, Cell } from '@teamsnap/teamsnap-ui';
import AccountsNewForm from 'components/baseComponents/AccountsNewForm';
import { matchPropShape, historyPropShape } from 'interfaces/navigation';
import { linkToAccounts } from 'utils/links';
import { Redirect } from 'react-router-dom';
class AccountsNew extends Component {
  submitPaymentAccount = async (values) => {
    const { division } = this.props;

    const attributes = {
      first_name: values.firstName,
      last_name: values.lastName,
      legal_entity_name: values.legalEntityName,
      nickname: values.nickname,
      payout_descriptor: values.payoutDescriptor,
      country: division.country === 'United States' ? 'US' : 'CA',
      currency: division.country === 'United States' ? 'USD' : 'CAD',
      business_type: division.businessType,
      processor: 'stripe',
      scope_id: division.formattedPersistentUuid,
      scope_type: 'division',
      division_id: division.id,
    };

    return this.submitCreate(attributes);
  };

  submitCreate = async (payload) => {
    const { createNewPaymentAccount, createOnboardingLink, division } = this.props;

    await createNewPaymentAccount(payload)
      .then((createdPaymentAccountResponse) => createOnboardingLink(createdPaymentAccountResponse.payload.id, {
          refresh_url: window.location.href,
          return_url: window.location.href,
          type: 'account_onboarding',
          division_id: division.id,
        }))
      .then((createdOnboardingLinkResponse) => {
        if (createdOnboardingLinkResponse) {
          const url = createdOnboardingLinkResponse.payload.url;

          if (url) {
            window.location.href = url;
          }
        }
      });
  };

  render() {
    const {
      division,
      onboardingLinkIsLoading,
      paymentAccount,
      match: { params },
    } = this.props;

    if (paymentAccount) {
      return <Redirect to={ linkToAccounts({ ...params }) } />;
    }

    return (
      <div>
        <Panel style={ { marginTop: '20px', width: '48%', marginLeft: 'auto', marginRight: 'auto' } }>
          <Grid isWithGutter isAlignMiddle>
            <Cell mods="u-size1of1" style={ { padding: '0 30px' } }>
              <AccountsNewForm
                creatingOnboardingLink={ onboardingLinkIsLoading }
                division={ division }
                submitPaymentAccount={ this.submitPaymentAccount }
              />
            </Cell>
          </Grid>
        </Panel>
      </div>
    );
  }
}

AccountsNew.propTypes = {
  showFlashMessage: PropTypes.func.isRequired,
  hideFlashMessage: PropTypes.func.isRequired,
  history: PropTypes.shape(historyPropShape).isRequired,
  match: PropTypes.shape(matchPropShape).isRequired,
  requestError: PropTypes.shape({}),
};

AccountsNew.defaultProps = {
  requestError: null,
};

export default AccountsNew;
