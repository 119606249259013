export const getGroupParams = ({ teamId, divisionId }) => {
  if (divisionId) return { divisionId };
  return { teamId };
};

export const getGroupId = ({ teamId, divisionId }) => teamId || divisionId;

export const getGroupType = (params) => {
  if (params.divisionId) return 'division';
  return 'team';
};

export const onGroupSettings = (pathname, params) => {
  if (pathname.match('accounts')) return true;
  if (getGroupType(params) === 'team') {
    return !!pathname.match(/team_financials$/);
  } else {
    return !!pathname.match(/league_financials$/);
  }
};
