import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showPortal, hidePortal } from 'state/app/actions';
import { selectPortal } from 'state/app/selectors';

class Portal extends Component {
  render () {
    const { component, componentProps, handlePortalClose, handlePortalOpen } = this.props;

    if (!component) { return null; }

    const PortalComponent = component;

    return (
      <PortalComponent handleClose={ handlePortalClose } handleOpen={ handlePortalOpen } { ...componentProps } />
    );
  }
}

Portal.propTypes = {
  handlePortalOpen: PropTypes.func.isRequired,
  handlePortalClose: PropTypes.func.isRequired,
  component: PropTypes.func,
  componentProps: PropTypes.shape({})
};

Portal.defaultProps = {
  component: null,
  componentProps: {}
};

const mapDispatchToProps = {
  handlePortalOpen: showPortal,
  handlePortalClose: hidePortal
};

const mapStateToProps = state => {
  const portal = selectPortal(state);

  return {
    component: portal.portalComponent,
    componentProps: portal.portalProps
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Portal);
