import { connect } from 'react-redux';
import {
  selectPayoutTransactions,
  selectPayoutTransactionsPaginate,
  selectPayoutTransactionsLoading
} from 'state/payoutTransactions/selectors';
import {
  fetchPayoutTransactions,
  fetchNextPayoutTransactions,
  fetchPrevPayoutTransactions
} from 'state/payoutTransactions/actions';
import { selectPaymentAccount } from 'state/paymentAccounts/selectors';
import { fetchPayout, clearPayouts } from 'state/payouts/actions';
import { selectPayout, selectPayoutsLoading } from 'state/payouts/selectors';
import DepositsShow from './DepositsShow';

const mapStateToProps = (state, props) => {
  const {
    match: {
      params: { depositId }
    }
  } = props;

  return {
    payoutTransactions: selectPayoutTransactions(state),
    paginate: selectPayoutTransactionsPaginate(state),
    paymentAccount: selectPaymentAccount(state),
    payout: selectPayout(state, depositId),
    payoutTransactionsLoading: selectPayoutTransactionsLoading(state),
    payoutsLoading: selectPayoutsLoading(state)
  };
};

const mapDispatchToProps = {
  fetchPayout,
  fetchPayoutTransactions,
  fetchNextPayoutTransactions,
  fetchPrevPayoutTransactions,
  clearPayouts
};

export default connect(mapStateToProps, mapDispatchToProps)(DepositsShow);
