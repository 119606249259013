import { formatValueName } from 'utils/formFields';

// Address validation methods
const validateLine1 = (line1) => {
  if (!line1) {
    return 'Address is required';
  } else if (line1.match(/^p(ost)?\.?\s?o(ffice)?\.?\sbox/i)) {
    return 'Physical address is required, PO boxes not accepted';
  }
};

const validateState = (state, paymentAccount) => {
  const country = paymentAccount.account.country;
  if (!state && country === 'US') {
    return 'State is required';
  } else if (!state && country === 'CA') {
    return 'Province is required';
  }
};

const validatePostalCode = (postalCode, paymentAccount) => {
  const country = paymentAccount.account.country;
  if (!postalCode) {
    return 'Postal Code is required';
  } else if (country === 'US' && !postalCode.match(/^[0-9]{5}$/)) {
    return 'Postal Code must be 5 digits';
  } else if (country === 'CA' && !postalCode.match(/^[0-9a-zA-Z]{3}[-\s]?[0-9a-zA-Z]{3}$/)) {
    return 'Postal Code must be 6 characters';
  }
};

const validateIdNumber = (idNumber, paymentAccount) => {
  const country = paymentAccount.account.country;
  const idTerm = `Social ${country === 'CA' ? 'Insurance' : 'Security'} Number`;

  if (!idNumber && country === 'US') {
    return `${idTerm} is required`;
  } else if (!idNumber && country === 'CA') {
    return undefined;
  } else if (country === 'US' && !idNumber.match(/^[0-9]{3}-?[0-9]{2}-?[0-9]{4}$/)) {
    return `Please provide a valid ${idTerm}: 'xxx-xx-xxxx'`;
  } else if (country === 'CA' && !idNumber.match(/^[0-9]{3}-?[0-9]{3}-?[0-9]{3}$/)) {
    return `Please provide a valid ${idTerm}: 'xxx-xxx-xxx'`;
  }
};

const validateSsnLast4 = (last4, paymentAccount) => {
  const country = paymentAccount.account.country;

  if (country === 'US' && !last4) {
    return 'Last 4 digits of SSN required';
  } else if (country === 'US' && !last4.match(/^[0-9]{4}$/)) {
    return 'Please enter 4 digits';
  } else if (country === 'CA' && last4 && !last4.match(/^[0-9]{4}$/)) {
    return 'Please enter 4 digits';
  }
};

// Bank account validation details
const validateRoutingNumber = (routingNumber, paymentAccount) => {
  const country = paymentAccount.account.country;
  if (!routingNumber) {
    return 'Routing Number is required';
  } else if (country === 'US' && !routingNumber.match(/^[0-9]{9}$/)) {
    return 'Routing Number must be 9 digits';
  } else if (country === 'CA' && !routingNumber.match(/^[0-9]{5}-{1}[0-9]{3}$/)) {
    return 'Must match format xxxxx-xxx';
  }
};

const validateAccountNumber = (accountNumber) => {
  if (!accountNumber) {
    return 'Account Number is required';
  } else if (!accountNumber.match(/^[0-9]*$/)) {
    return 'Account Number must be digits only';
  }
};

const validateAccountNumber2 = (accountNumber2, _paymentAccount, allValues) => {
  const accountNumber = allValues.accountNumber && allValues.accountNumber.trim();
  if (accountNumber !== accountNumber2) {
    return 'Account Numbers must match';
  }
};

// Company ID verification methods
const validateCompanyTaxId = (companyTaxId) => {
  if (!companyTaxId) {
    return 'Company Tax ID is required';
  } else if (!companyTaxId.match(/^[0-9]{2}-?[0-9]{7}$/)) {
    return 'Please provide a valid Company Tax ID xx-xxxxxxx';
  }
};

const validateCompanyPhone = (companyPhone) => {
  if (!companyPhone) {
    return 'Company phone number is required';
  } else if (!companyPhone.match(/^\(?[0-9]{3}\)?[-.\s]?[0-9]{3}[-.\s]?[0-9]{4}$/)) {
    return 'Phone number must be 10 digits';
  }
};

const validatePayoutDescriptor = (payoutDescriptor) => {
  if (!payoutDescriptor) {
    return 'Payout Descriptor is required';
  } else if (payoutDescriptor.length < 5 || payoutDescriptor.length > 22) {
    return 'Must be between 5 and 22 Latin characters, containing at least one letter.';
  } else if (!isValidDescriptor(payoutDescriptor)) {
    return 'Must be between 5 and 22 Latin characters, containing at least one letter.';
  }
};

const isValidDescriptor = (value) => {
  const regex = /^[a-zA-Z0-9 ]*$/;
  const isAllLatinCharacters = regex.test(value);
  const containsOneLetter = value.match(/[a-zA-Z]/g)?.length >= 1;

  return isAllLatinCharacters && containsOneLetter;
};

const namesToValidations = {
  addressLine1: validateLine1,
  addressState: validateState,
  addressPostalCode: validatePostalCode,
  idNumber: validateIdNumber,
  ssnLast4: validateSsnLast4,
  routingNumber: validateRoutingNumber,
  accountNumber: validateAccountNumber,
  accountNumber2: validateAccountNumber2,
  companyTaxId: validateCompanyTaxId,
  companyAddressLine1: validateLine1,
  companyAddressState: validateState,
  companyAddressPostalCode: validatePostalCode,
  companyPhone: validateCompanyPhone,
  payoutDescriptor: validatePayoutDescriptor,
};

const validateRequiredValue = (value, name) => {
  if (!value || !value.trim()) {
    return `${formatValueName(name)} is required`;
  }
};

export const validate = (value, paymentAccount, name, isRequired, allValues) => {
  const trimmedValue = value && value.trim();
  if (namesToValidations[name]) {
    return namesToValidations[name](trimmedValue, paymentAccount, allValues);
  } else if (!isRequired) {
    return undefined;
  } else {
    return validateRequiredValue(trimmedValue, name);
  }
};
