import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import ErrorBoundary from 'components/baseComponents/ErrorBoundary';

class AppRoute extends Component {
  render () {
    const { path, exact, strict, component, otherProps } = this.props;
    const RouteComponent = component;

    return (
      <Route
        path={ path }
        exact={ exact }
        strict={ strict }
        render={ params =>
          <ErrorBoundary>
            <RouteComponent { ...params } { ...otherProps } />
          </ErrorBoundary> }
      />
    );
  }
}

AppRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  exact: PropTypes.bool,
  strict: PropTypes.bool,
  otherProps: PropTypes.shape({})
};

AppRoute.defaultProps = {
  exact: false,
  strict: false,
  otherProps: {}
};

export default AppRoute;
