import { getCharges, getPayouts, getPayoutTransactions } from 'services/payments';
import { selectPaymentAccountId } from 'state/paymentAccounts/selectors';

// ----------------------------------------------------------------------------------------------------
// :: Action Types
// ----------------------------------------------------------------------------------------------------
export const EXPORT_DATA = 'EXPORT_DATA';
const FETCH_EXPORT_DATA = 'FETCH_EXPORT_DATA';
const SAVE_EXPORT_DATA = 'SAVE_EXPORT_DATA';
const ERROR_EXPORT_DATA = 'ERROR_EXPORT_DATA';

// ----------------------------------------------------------------------------------------------------
// :: Action Creators
// ----------------------------------------------------------------------------------------------------
const formatData = (actionType, response) => ({
    type: actionType,
    payload: { data: response.data, meta: response.meta }
  });

const getLastId = (response) => {
  const last = response.data[response.data.length - 1];
  return last && last.id;
};

const getAllEntries = async (params, getRequest, accumulatedData = []) => {
  const response = await getRequest(params);
  const newData = [...accumulatedData, ...response.data];
  if (response && response.meta && response.meta.hasMore) {
    return getAllEntries({ ...params, startingAfter: getLastId(response) }, getRequest, newData);
  } else {
    return newData;
  }
};

export const fetchAllCharges = (params = {}) => async (dispatch, getState) => {
  dispatch({ type: FETCH_EXPORT_DATA });
  const accountId = selectPaymentAccountId(getState());
  try {
    const allCharges = await getAllEntries({ ...params, accountId, limit: 100 }, getCharges);
    return dispatch(formatData(SAVE_EXPORT_DATA, { data: allCharges, meta: null }));
  } catch (error) {
    dispatch({ type: ERROR_EXPORT_DATA, payload: error });
    return Promise.reject(error);
  }
};

export const fetchAllPayouts = (params = {}) => async (dispatch, getState) => {
  dispatch({ type: FETCH_EXPORT_DATA });
  const accountId = selectPaymentAccountId(getState());
  try {
    const allPayouts = await getAllEntries({ accountId, ...params, limit: 100 }, getPayouts);
    return dispatch(formatData(SAVE_EXPORT_DATA, { data: allPayouts, meta: null }));
  } catch (error) {
    dispatch({ type: ERROR_EXPORT_DATA, payload: error });
    return Promise.reject(error);
  }
};

export const fetchAllPayoutTransactions = (payoutId, params = {}) => async (dispatch, getState) => {
  dispatch({ type: FETCH_EXPORT_DATA });
  const accountId = selectPaymentAccountId(getState());
  try {
    const allPayoutTransactions = await getAllEntries(
      { accountId, payoutId, ...params, limit: 50 },
      getPayoutTransactions
    );
    return dispatch(formatData(SAVE_EXPORT_DATA, { data: allPayoutTransactions, meta: null }));
  } catch (error) {
    dispatch({ type: ERROR_EXPORT_DATA, payload: error });
    return Promise.reject(error);
  }
};
