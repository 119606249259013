import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const Tabs = ({ tabs }) => (
  <nav className='Tabs-header'>
    { tabs.map(tab => (
      <NavLink to={ tab.to } className='Tabs-headerItem' activeClassName='is-active' key={ tab.label }>
        { tab.label }
      </NavLink>
    )) }
  </nav>
);

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired
};

export default Tabs;
