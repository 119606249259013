import AXIOS from 'axios';
import { convertFromCollection, objectKeysToCamel } from '../utils/collectionJson';
import { authToken, apiv3Url, teamId } from 'utils/window';

// Setup local instance of axios with default url and headers
const axios = AXIOS.create({
  baseURL: apiv3Url || 'http://localhost:3000',
  headers: {
    Accept: 'application/vnd.api.v1.0.0+json',
    authorization: `Bearer ${authToken}`,
  },
});

// Add start and end times to axios request and response objects
axios.interceptors.request.use((config) => ({
  ...config,
  startTime: new Date().getTime(),
}));

const onGetSuccess = (result) => {
  const {
    config: { url, params, method, headers, startTime },
    data,
    status,
    statusText,
  } = result;
  const team = convertFromCollection(data).items[0];
  console.log('Success - team GET', {
    request: { time: `${new Date().getTime() - startTime} ms`, url, params, method, headers, status, statusText },
    response: data,
    data: team,
  });

  return team;
};

const onError = (error) => {
  console.log('Team API Request Failed: ', error);
  return Promise.reject(error);
};

export const teamApiAxiosClient = axios;

export const getTeamAttributeRequest = () =>
  axios({
    method: 'GET',
    url: '/teams/search',
    params: {
      team_id: teamId,
    },
  })
    .then((data) => onGetSuccess(data))
    .catch(onError);
