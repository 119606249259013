import React, { Component } from 'react';
import styled from '@emotion/styled';
import { Route } from 'react-router-dom';
import Root from 'components/screens/Root';
import LeagueFinancials from 'components/baseComponents/LeagueFinancials';
import TeamFinancials from 'components/baseComponents/TeamFinancials';
import PortalRoot from 'components/baseComponents/PortalRoot';
import Portal from 'components/baseComponents/Portal';
import { datadogRum } from '@datadog/browser-rum';
import 'assets/index.css';

// Emotion Tagged Template Literal (es6) Notation
const StyledApp = styled.div`
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  position: 'relative';
`;

// Emotion Object Style Notation
// const StyledApp = styled.div({
//   maxWidth: 1300,
//   width: '100%',
//   margin: '0 auto'
// })
//

const initDataDog = () => {
  datadogRum.init({
    applicationId: '701cfdf3-dfe6-4ae8-95af-6e995ab0050e',
    clientToken: 'pub1cb4f8276ece443f5e527d0dca0c02f7',
    site: 'datadoghq.com',
    service: 'Payments Frontend',
    env: 'production',
    sampleRate: 10,
    trackInteractions: false,
  });
};

const initLocalTeamSnapObject = () => {
  if (process.env.NODE_ENV === 'development') {
    const configJson = JSON.parse(process.env.REACT_APP_TEAMSNAP_CONFIG);
    window.TeamSnap = {
      ...window.TeamSnap,
      ...configJson,
    };
  }
};
class App extends Component {
  render() {
    initDataDog();
    initLocalTeamSnapObject();
    return (
      <StyledApp>
        <Route path="/" component={ Root } exact />
        <Route path="/:divisionId/league_financials" component={ LeagueFinancials } />
        <Route path="/:teamId/team_financials" component={ TeamFinancials } />

        <PortalRoot>
          <Portal />
        </PortalRoot>
      </StyledApp>
    );
  }
}

export default App;
