const formatItem = (item, renderCSV) => {
  if (!item || item === null) return '';
  if (renderCSV) item = renderCSV(item);
  if (item.match(',')) {
    return `"${item}"`;
  } else {
    return item;
  }
};

export const convertToCSV = (columns, rows) => {
  if (!rows || rows.length === 0) return '';
  const columnsToRender = columns.filter(column => column.renderCSV !== null);
  const result = rows.map(item => columnsToRender.map(key => formatItem(item[key.name], key.renderCSV)).join(','));
  result.unshift(columnsToRender.map(column => column.label).join(','));
  return result.join('\r\n');
};

export const noMessageResponse = 'Something went wrong. Please check that the information you entered is correct.';
export const failedUpdateResponse = 'Something went wrong. Please contact TeamSnap at payments@teamsnap.com.';

export const formatErrorMessage = message => {
  if (!message) {
    return noMessageResponse;
  } else if (/stripe/i.test(message) || message === 'Error: Network Error') {
    return failedUpdateResponse;
  }
  return message;
};
