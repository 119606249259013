import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import NoMatch from 'components/screens/NoMatch';
import AppRoute from 'components/baseComponents/AppRoute';

class AppRoutes extends Component {
  render () {
    const { routes, rootPath, ...rest } = this.props;

    return (
      <Switch>
        { routes.map(route => {
          if (route.redirect) {
            return <Redirect key={ `${route.path}-redirect` } from={ route.path } to={ route.redirect } exact />;
          } else {
            return (
              <AppRoute
                key={ route.path }
                path={ rootPath ? `${rootPath}${route.path}` : route.path }
                exact={ route.exact }
                strict={ route.strict }
                component={ route.component }
                otherProps={ rest }
              />
            );
          }
        }) }

        <Route component={ NoMatch } />
      </Switch>
    );
  }
}

AppRoutes.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  rootPath: PropTypes.string
};

AppRoutes.defaultProps = {
  rootPath: '/'
};

export default AppRoutes;
