import { connect } from 'react-redux';
import { selectPaymentAccount, selectPaymentAccountIsLoading } from 'state/paymentAccounts/selectors';
import { fetchPaymentAccountsByTeam } from 'state/paymentAccounts/actions';
import { selectFormFieldsAreLoading } from 'state/formFields/selectors';
import { fetchFormFields } from 'state/formFields/actions';
import { showFlashMessage, hideFlashMessage } from 'state/app/actions';
import TeamFinancials from './TeamFinancials';
import { featureRollout, isTeam, teamSnapWindow } from 'utils/window';

const mapStateToProps = (state, ownProps) => ({
  paymentAccount: selectPaymentAccount(state),
  paymentAccountIsLoading: selectPaymentAccountIsLoading(state),
  formFieldsAreLoading: selectFormFieldsAreLoading(state),
  isVisaMandateTeam: isTeam && featureRollout('invoicing_frontend_visa_mandate_standalone'),
  userIsTeamOwner: teamSnapWindow('userIsTeamOwner'),
});

const mapDispatchToProps = {
  fetchPaymentAccountsByTeam,
  fetchFormFields,
  showFlashMessage,
  hideFlashMessage
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamFinancials);
