import React from 'react';
import PropTypes from 'prop-types';
import provinces from 'utils/ca-provinces';
import Select from './Select';
import FieldError from 'components/teamsnapUIExtensions/FieldError';
import { metaPropShape } from 'interfaces/field';

const ProvinceSelect = ({ meta, input }) => {
  const options = provinces.map(prov => ({ label: prov.name, value: prov.abbreviation }));

  return (
    <>
      <Select field={ input } options={ [{ label: 'Select Province', value: '' }, ...options] } />
      { meta.error && meta.touched && <FieldError>{ meta.error }</FieldError> }
    </>
  );
};

ProvinceSelect.propTypes = {
  input: PropTypes.shape({}).isRequired,
  meta: PropTypes.shape(metaPropShape).isRequired
};

export default ProvinceSelect;
