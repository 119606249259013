import { Input as InputUI } from '@teamsnap/teamsnap-ui';
import FieldError from 'components/teamsnapUIExtensions/FieldError';
import { metaPropShape } from 'interfaces/field';
import PropTypes from 'prop-types';
import React from 'react';

const Input = props => {
  const { input, meta, placeholder, fieldWidth } = props;
  return (
    <>
      <InputUI type='input' style={ { width: fieldWidth } } name={ input.name } inputProps={ { ...input, placeholder } } />
      { meta.error && meta.touched && <FieldError>{ meta.error }</FieldError> }
    </>
  );
};

Input.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  placeholder: PropTypes.string,
  fieldWidth: PropTypes.string,
  meta: PropTypes.shape(metaPropShape).isRequired
};

Input.defaultProps = {
  placeholder: null,
  fieldWidth: '100%'
};

export default Input;
