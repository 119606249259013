export const teamSnapWindow = (value) => {
   // we can pass a json string in the env variable REACT_APP_TEAMSNAP_CONFIG when not using classic shell
   const configJson = process.env.REACT_APP_TEAMSNAP_CONFIG ? JSON.parse(process.env.REACT_APP_TEAMSNAP_CONFIG) : {};
   return configJson[value] || window.TeamSnap[value];
};

export const authToken = teamSnapWindow('authenticationToken');
export const apiv3Url = teamSnapWindow('apiv3Url');
export const paymentsApiUrl = teamSnapWindow('paymentsApiUrl');
export const teamId = teamSnapWindow('teamId');
export const featureRollouts = teamSnapWindow('featureRollouts') || {};
export const isTeam = !!teamId;
export const isStandaloneTeam = teamSnapWindow('isStandaloneTeam');

export const featureRollout = (feature) => featureRollouts[feature] === true;
