import React from 'react';
import PropTypes from 'prop-types';
import { Form, reduxForm } from 'redux-form';
import { Grid, Cell } from '@teamsnap/teamsnap-ui';
import FormGroup from './FormGroup';
import { StyledButton, StyledSubmitButton } from './styled';
import { paymentAccountPropShape } from 'interfaces/paymentAccount';

import { getTeamAttributeRequest } from 'services/team-api';

const DynamicAccountsForm = props => {
  const [isInLeague, setIsInLeague] = React.useState(false);

  const {
    handleSubmit,
    formFieldsBySection,
    isEditing,
    initialValues,
    submitting,
    handleCancel,
    paymentAccount,
  } = props;

  React.useEffect(() => {
    if (window.TeamSnap.divisionId) {
      setIsInLeague(true);
    } else {
      getTeamAttributeRequest().then((team) => {
        setIsInLeague(team.isInLeague);
      });
    }
  }, []);

  return (
    <Form onSubmit={ handleSubmit }>
      <Grid isWithGutter>
        { formFieldsBySection ? formFieldsBySection.map((section, i) => (
          <FormGroup
            key={ i }
            title={ section.title }
            subtitle={ section.subtitle }
            fields={ section.fields }
            isEditing={ isEditing }
            initialValues={ initialValues }
            paymentAccount={ paymentAccount }
          />
        )) : <div /> }
      </Grid>
      <Grid isWithGutter>
        { isEditing && paymentAccount && paymentAccount.isSetupComplete &&
          <Cell mods='u-size1of2'>
            <StyledButton isDisabled={ submitting } icon='dismiss' size='large' color='negative' onClick={ handleCancel }>
              Cancel
            </StyledButton>
          </Cell> }

        <Cell mods={ isEditing && paymentAccount && paymentAccount.isSetupComplete ? 'u-size1of2' : 'u-size1of1' }>
          { !isEditing && !isInLeague && (
            <p>To update your banking information, please contact payments@teamsnap.com.
              For security purposes, please do not send any sensitive banking data in email.
            </p>
          ) }
          <StyledSubmitButton isDisabled={ submitting || (!isEditing && !isInLeague) } icon={ isEditing ? 'check' : 'edit' } color={ isEditing ? 'primary' : null }>
            { isEditing ? 'Start Receiving Deposits' : 'Edit Deposit Settings' }
          </StyledSubmitButton>
        </Cell>
      </Grid>
    </Form>
  );
};

DynamicAccountsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  formFieldsBySection: PropTypes.array,
  isEditing: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  handleCancel: PropTypes.func,
  paymentAccount: PropTypes.shape(paymentAccountPropShape).isRequired,
  isInLeague: PropTypes.bool
};

DynamicAccountsForm.defaultProps = {
  formFieldsBySection: [],
  handleCancel: null,
  isInLeague: false
};

export default reduxForm({ form: 'account' })(DynamicAccountsForm);
