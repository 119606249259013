import { connect } from 'react-redux';
import { selectDivision } from 'state/division/selectors';
import { showFlashMessage, hideFlashMessage } from 'state/app/actions';
import { createNewPaymentAccount } from 'state/paymentAccounts/actions';
import { createOnboardingLink } from 'state/onboardingLink/actions';
import { selectOnboardingLinkIsLoading } from 'state/onboardingLink/selectors';
import { selectPaymentAccount } from 'state/paymentAccounts/selectors';
import AccountsNew from './AccountsNew';

const mapDispatchToProps = {
  showFlashMessage,
  hideFlashMessage,
  createNewPaymentAccount,
  createOnboardingLink,
};

const mapStateToProps = (state) => ({
  division: selectDivision(state),
  onboardingLinkIsLoading: selectOnboardingLinkIsLoading(state),
  paymentAccount: selectPaymentAccount(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountsNew);
