import { getPayoutTransactions } from 'services/payments';
import { selectPaymentAccountId } from 'state/paymentAccounts/selectors';
import { arrayToObject } from 'utils/helpers';

// ----------------------------------------------------------------------------------------------------
// :: Action Types
// ----------------------------------------------------------------------------------------------------
export const PAYOUT_TRANSACTIONS = 'PAYOUT_TRANSACTIONS';
const FETCH_PAYOUT_TRANSACTIONS = 'FETCH_PAYOUT_TRANSACTIONS';
const SAVE_PAYOUT_TRANSACTIONS = 'SAVE_PAYOUT_TRANSACTIONS';
const SAVE_NEXT_PAYOUT_TRANSACTIONS = 'SAVE_NEXT_PAYOUT_TRANSACTIONS';
const SAVE_PREV_PAYOUT_TRANSACTIONS = 'SAVE_PREV_PAYOUT_TRANSACTIONS';
const ERROR_PAYOUT_TRANSACTIONS = 'ERROR_PAYOUT_TRANSACTIONS';

// ----------------------------------------------------------------------------------------------------
// :: Action Creators
// ----------------------------------------------------------------------------------------------------

// PayoutTransactions returns a total balance and we drop this from view.  So to display 50 records, we need to request 51.
const getPaymentPayoutTransactions = (params = {}) => getPayoutTransactions({ limit: 51, ...params });

const saveFetchPayoutTransactions = (actionType, response) => {
  const filtered = response.data.length > 0 ?
    response.data.filter((_item, index) => index === 0 || index === response.data.length - 1).map(x => x.id) : [];

  const paginate = { endingBefore: filtered[0], startingAfter: filtered[1] };

  const mappedData = arrayToObject(response.data, 'id');

  return {
    type: actionType,
    payload: { paginate, data: mappedData, meta: response.meta }
  };
};

export const fetchPayoutTransactions = (payoutId, params = {}) => async (dispatch, getState) => {
  dispatch({ type: FETCH_PAYOUT_TRANSACTIONS });
  const accountId = selectPaymentAccountId(getState());

  try {
    const response = await getPaymentPayoutTransactions({ accountId, payoutId, ...params });
    return dispatch(saveFetchPayoutTransactions(SAVE_PAYOUT_TRANSACTIONS, response));
  } catch (error) {
    dispatch({ type: ERROR_PAYOUT_TRANSACTIONS, payload: error });
    return Promise.reject(error);
  }
};

export const fetchNextPayoutTransactions = (payoutId, params = {}) => async (dispatch, getState) => {
  dispatch({ type: FETCH_PAYOUT_TRANSACTIONS });
  const accountId = selectPaymentAccountId(getState());
  const { startingAfter, hasNext } = getState().payoutTransactions.paginate;
  const pageParams = hasNext ? { ...params, startingAfter } : { ...params };

  try {
    const response = await getPaymentPayoutTransactions({ accountId, payoutId, ...pageParams });
    return dispatch(saveFetchPayoutTransactions(SAVE_NEXT_PAYOUT_TRANSACTIONS, response));
  } catch (error) {
    dispatch({ type: ERROR_PAYOUT_TRANSACTIONS, payload: error });
    return Promise.reject(error);
  }
};

export const fetchPrevPayoutTransactions = (payoutId, params = {}) => async (dispatch, getState) => {
  dispatch({ type: FETCH_PAYOUT_TRANSACTIONS });
  const accountId = selectPaymentAccountId(getState());
  const { endingBefore, hasPrev } = getState().payoutTransactions.paginate;
  const pageParams = hasPrev ? { ...params, endingBefore } : { ...params };

  try {
    const response = await getPaymentPayoutTransactions({ accountId, payoutId, ...pageParams });
    return dispatch(saveFetchPayoutTransactions(SAVE_PREV_PAYOUT_TRANSACTIONS, response));
  } catch (error) {
    dispatch({ type: ERROR_PAYOUT_TRANSACTIONS, payload: error });
    return Promise.reject(error);
  }
};
